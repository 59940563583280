export const appColors = {
  accounts: {
    appColor: '#4B7ADC',
    fillColor: '#48a6dd',
  },
  check_ins: {
    appColor: '#816393',
    fillColor: '#8a6bcb',
  },
  people: {
    appColor: '#4B7ADC',
    fillColor: '#81a3f0',
  },
  calendar: {
    appColor: '#FF4226',
    fillColor: '#FF4226',
  },
  services: {
    appColor: '#739446',
    fillColor: '#82c069',
  },
  headcounts: {
    appColor: '#A867B9',
    fillColor: '#A867B9',
  },
  church_center_app: {
    appColor: '#01A8B9',
    fillColor: '#01A8B9',
  },
  music_stand: {
    appColor: '#0296F4',
    fillColor: '#23AFF1',
  },
}