import request from 'superagent'
import sweetalert from 'sweetalert'

export const SAVE_DASHBOARD = 'SAVE_DASHBOARD'
export const SAVED_DASHBOARD = 'SAVED_DASHBOARD'
export const UPDATED_DASHBOARD = 'UPDATED_DASHBOARD'

export const saveDashboard = (id, config, dispatch) => {
  const url = id ? `/saved/${id}` : '/saved'

  request
    .post(url)
    .send(config)
    .then(response => {
      const newId = response.body.id
      if (newId != id) {
        window.history.pushState({}, `Dashboard #${newId}`, `/saved/${newId}`)
      }

      dispatch({ type: SAVED_DASHBOARD, payload: { id: newId, }, })

      sweetalert({
        title: 'Saved!',
        icon: 'success',
        html: `Share Link: <a href='http://dash.pco.bz/saved/${newId}'>http://dash.pco.bz/saved/${newId}</a>`,
      })
    })

  return { type: SAVE_DASHBOARD, }
}
