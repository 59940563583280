import React, { Component } from 'react'
import { Motion, spring } from 'react-motion'

import '../styles/cross-fade.scss'

class CrossFade extends Component {
  constructor(props) {
    super(props)
    this.state = { previousChildren: [] }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.children != this.state.previousChildren) {
      this.setState({
        previousChildren: this.props.children,
        nextKey: new Date().getTime().toString(),
      })
    }
  }

  render() {
    const { previousChildren, nextKey } = this.state

    return (
      <div className="cross-fade-container">
        <div className="cross-fade-item cross-fade-next">
          {this.props.children}
        </div>
        {previousChildren && (
          <Motion
            defaultStyle={{ opacity: 1 }}
            key={nextKey}
            style={{ opacity: spring(0, { stiffness: 120, damping: 40 }) }}
          >
            {({ opacity }) => (
              <div
                className="cross-fade-item cross-fade-previous"
                style={{ opacity: opacity }}
              >
                {previousChildren}
              </div>
            )}
          </Motion>
        )}
      </div>
    )
  }
}

export default CrossFade
