import React from 'react'

const Oncall = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M70,12C54-4,28-4,12,12C-4,28-4,54,12,70c8,8,18.5,12,29,12c10.5,0,21-4,29-12C86,54,86,28,70,12z M13.5,61.1l9.2-9.2
    	c1.1-1.1,1.5-2.8,1-4.3c-2.4-6.7-1-14.6,3.4-19.5c4-4.5,9.7-6.7,15.5-6.3l-9.9,9.9c-2.5,2.5-2.5,6.5,0,9l8.6,8.6
    	c2.5,2.5,6.5,2.5,9,0l9.9-9.9c0.4,5.4-1.6,10.8-5.5,14.8c-5.3,5.3-13.3,7-20.3,4.3c-1.5-0.6-3.2-0.2-4.4,0.9l-9,9
    	c-1.4-1-2.7-2.2-4-3.4C15.7,63.8,14.6,62.5,13.5,61.1z M65.1,65.1c-10.2,10.2-25.4,12.6-37.9,7l6.4-6.4c9.1,2.8,19.1,0.3,25.9-6.5
    	c7.1-7.1,9.5-17.7,6.1-27.1c-0.5-1.3-1.5-2.2-2.9-2.5c-1.3-0.3-2.7,0.1-3.7,1.1L45.7,44L38,36.3l13.4-13.4c1-1,1.4-2.4,1.1-3.7
    	c-0.3-1.3-1.3-2.4-2.5-2.9c-10-3.6-21-0.8-28.1,7.2c-5.7,6.3-7.7,16.1-5.3,24.7l-6.6,6.6C4.4,42.4,6.7,27.2,17,17
    	C23.6,10.3,32.3,7,41,7c8.7,0,17.4,3.3,24.1,10C78.4,30.2,78.4,51.8,65.1,65.1z"/>
  </svg>
)

Oncall.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Oncall
