import React from 'react'

const Layout = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M95.541,20.794H20.457c-2.091,0-3.786,1.667-3.786,3.721v66.97c0,2.054,1.694,3.721,3.786,3.721h75.084
			c2.092,0,3.788-1.667,3.788-3.721v-66.97C99.329,22.461,97.633,20.794,95.541,20.794z M91.756,48.911H63.047V28.236h28.708V48.911z
			 M24.243,28.236h31.233v59.529H24.243V28.236z M63.047,87.764V56.353h28.708v31.412H63.047z"
    />
  </svg>
)

Layout.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Layout
