import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import Dashboard from './components/Dashboard'
import reducers from './reducers'

import './styles/general.scss'

let store = null
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose

if (window.initialState) {
  store = createStore(reducers, window.initialState, composeEnhancers())
} else {
  const persistConfig = {
    key: 'root',
    blacklist: ['dashboard', 'status'],
    storage,
  }  
  const initialState = { widgets: [], layout: [] }
  const persistedReducer = persistReducer(persistConfig, reducers)
  store = createStore(persistedReducer, initialState, composeEnhancers())
  persistStore(store)
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <Dashboard />
    </Provider>,
    document.getElementById('app')
  )
})
