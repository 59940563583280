import React from 'react'

const ArrowBack = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M58.399,28.116c-17.013,0-30.855,13.841-30.855,30.856c0,17.014,13.842,30.856,30.855,30.856
			c17.015,0,30.857-13.842,30.857-30.856C89.256,41.958,75.414,28.116,58.399,28.116z M58.399,83.656
			c-13.611,0-24.684-11.074-24.684-24.684c0-13.612,11.073-24.685,24.684-24.685c13.612,0,24.685,11.074,24.685,24.685
			C83.084,72.583,72.011,83.656,58.399,83.656z M62.39,49.523l-4.363-4.364L44.214,58.972l13.813,13.812l4.363-4.363l-6.363-6.364
			h14.662v-6.171H56.027L62.39,49.523z"
    />
  </svg>
)

ArrowBack.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#c3c3c3',
}

export default ArrowBack
