import React, { Component, Children, PropTypes } from 'react'
import { connect } from 'react-redux'
import { updateSetting, updateWidget } from '../actions/widgets'
import { toggleSettingsActive } from '../actions/status'
import { updateLayoutKey } from '../actions/settings'
import capitalize from '../utils/capitalize'
import * as Icons from 'glob:./icons/*.js'

import '../styles/settings.scss'

class CardSettings extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    component: PropTypes.node,
    id: PropTypes.string,
  }

  _id = +(Date.now() * Math.random())

  _updateSetting = (key, value) => {
    const { id, dispatch, component } = this.props

    dispatch(updateSetting(id, key, value))
    if (component.type.widgetProperties) {
      const newProps = { ...component.props, [key]: value }
      const currentDimensions = component.type.widgetProperties(component.props)
      const newDimensions = component.type.widgetProperties(newProps)

      if (
        currentDimensions.height !== newDimensions.height ||
        currentDimensions.width !== newDimensions.width
      ) {
        dispatch(updateLayoutKey())
      }
      dispatch(updateWidget(id, component.type.widgetProperties(newProps)))
    }
  }

  _renderInput(key, setting) {
    const { component } = this.props
    return (
      <div key={key} className="setting-row">
        <div className="setting">
          <label htmlFor={`${this._id}-input-${key}`} children={setting.name} />
          <input
            id={`${this._id}-input-${key}`}
            type="text"
            onBlur={({ target: { value } }) => this._updateSetting(key, value)}
            defaultValue={component.props[key]}
          />
        </div>
      </div>
    )
  }

  _renderSelect(key, setting) {
    const { component } = this.props
    return (
      <div key={key} className="setting-row">
        <div className="setting">
          <label
            htmlFor={`${this._id}-select-${key}`}
            children={setting.name}
          />
          <select
            id={`${this._id}-select-${key}`}
            onChange={({ target: { value } }) =>
              this._updateSetting(key, value)
            }
            value={component.props[key]}
          >
            {setting.options.map((option, i) => (
              <option key={i} value={option[1]}>
                {option[0]}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  _renderCheckbox(key, setting) {
    const { component } = this.props
    return (
      <div key={key} className="setting-row">
        <div className="setting">
          <label
            htmlFor={`${this._id}-switch-${key}`}
            children={setting.name}
          />
          <input
            id={`${this._id}-switch-${key}`}
            type="checkbox"
            checked={component.props[key]}
            hidden
            onChange={() => this._updateSetting(key, !component.props[key])}
          />
          <label htmlFor={`${this._id}-switch-${key}`} className="switch" />
        </div>
        {!!component.props[key] &&
          !!setting.settings &&
          this._renderSettings(setting.settings)}
      </div>
    )
  }

  _renderNumber(key, setting) {
    const { component } = this.props
    return (
      <div key={key} className="setting">
        <label htmlFor={`${this._id}-${key}`} children={setting.name} />
        <input
          id={`${this._id}-${key}`}
          onChange={({ target: { value } }) => this._updateSetting(key, value)}
          type="number"
          value={component.props[key]}
          min={setting.min}
          max={setting.max}
          step={setting.step}
        />
      </div>
    )
  }

  _renderSettings(settings) {
    return (
      <div className="settings">
        {Object.keys(settings).map(key => {
          const setting = settings[key]
          switch (setting.type) {
            case 'input':
              return this._renderInput(key, setting)
            case 'select':
              return this._renderSelect(key, setting)
            case 'checkbox':
              return this._renderCheckbox(key, setting)
            case 'number':
              return this._renderNumber(key, setting)
          }
        })}
      </div>
    )
  }

  render() {
    const { component, type, id, dispatch } = this.props
    const { settings } = component.type

    return (
      <div className="card-settings">
        <header className="card-header">
          <div>{capitalize(type)} Settings</div>
          <div
            className="card-settings-return"
            onClick={() => dispatch(toggleSettingsActive(id))}
          >
            <Icons.ArrowBack /> Back
          </div>
        </header>
        {this._renderSettings(settings)}
      </div>
    )
  }
}

export default connect()(CardSettings)
