import { UPDATE_STATS } from '../actions/stats'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_STATS:
      const { app, data } = action.payload
      return { ...state, [app]: { ...state[app], ...data } }
    default:
      return state
  }
}
