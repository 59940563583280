import React from 'react'

const Rocket = ({ width, height, fill }) => (
    <svg width={width} height={height} viewBox="0 96 960 960" fill={fill}>
        <path d="m220 888 106-43q-13-38-22.5-77T287 689l-67 45v154Zm166-52h188q22-59 34-117.5T620 616q0-115-33-202T480 249q-74 78-107 165t-33 202q0 44 12 102.5T386 836Zm94-230q-29 0-49.5-20.5T410 536q0-29 20.5-49.5T480 466q29 0 49.5 20.5T550 536q0 29-20.5 49.5T480 606Zm260 282V734l-67-45q-7 40-16.5 79T634 845l106 43ZM480 167q102 90 151 199.5T680 616v5l93 63q13 8 20 21t7 28v243l-200-80H360l-200 80V733q0-15 7-28t20-21l93-63v-5q0-140 49-249.5T480 167Z" />
    </svg>
)

Rocket.defaultProps = {
    width: '30px',
    height: '30px',
    fill: '#fff',
}

export default Rocket