import React from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import Card from './Card'
import { connect } from 'react-redux'
import { updateLayout } from '../actions/layout'
import * as Icons from 'glob:./icons/*.js'

import '../styles/react-grid.scss'

const TOTAL_COLS = 9
const COL_WIDTH = 3
const COL_HEIGHT = 3
const ResponsiveReactGridLayout = WidthProvider(Responsive)

class Grid extends React.Component {
  static defaultProps = {
    layout: [],
    isResizable: false,
    breakpoints: { lg: 800, xxs: 0 },
    cols: { lg: TOTAL_COLS, xxs: 3 },
    className: 'layout',
    draggableHandle: '.react-grid-handle',
  }

  handleLayoutChange = (layout) => {
    this.props.dispatch(updateLayout(layout))
  }

  renderWidgets() {
    const { widgets } = this.props
    const generatedLayout = this.generateLayout()
    return widgets.map((widget, index) => {
      const settingsActive = this.props.settingsActive.indexOf(widget.id) !== -1
      return (
        <div key={widget.id} data-grid={generatedLayout[index]}>
          {!settingsActive && (
            <div className="react-grid-handle">
              <Icons.Move />
            </div>
          )}
          <Card {...widget} />
        </div>
      )
    })
  }

  generateLayout() {
    const { widgets, layout } = this.props

    return widgets.map((widget, index) => {
      const defaultX = (index * COL_WIDTH) % TOTAL_COLS
      const defaultY = Math.ceil(index / TOTAL_COLS)
      const x = layout[index] ? layout[index].x : defaultX
      const y = layout[index] ? layout[index].y : defaultY
      const h = widget.height || COL_HEIGHT
      const w = widget.width || COL_WIDTH
      return { h: h, w: w, x: x, y: y }
    })
  }

  render() {
    return (
      <ResponsiveReactGridLayout
        key={this.props.updatedAt}
        onLayoutChange={this.handleLayoutChange}
        rowHeight={169}
        {...this.props}
      >
        {this.renderWidgets()}
      </ResponsiveReactGridLayout>
    )
  }
}

export default connect(state => ({
  widgets: state.widgets,
  layout: state.layout,
  updatedAt: state.settings.gridUpdatedAt,
  settingsActive: state.status.settingsActive,
}))(Grid)
