import { SAVED_DASHBOARD } from '../actions/dashboard'

export default (state = {}, action) => {
  switch (action.type) {
    case SAVED_DASHBOARD:
      return action.payload
    default:
      return state
  }
}
