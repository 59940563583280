import React from 'react'

const Check = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 96 960 960" fill={fill}>
    <path d="M480 976q-85 0-158-30.5T195 861q-54-54-84.5-127T80 576q0-84 30.5-157T195 292q54-54 127-85t158-31q75 0 140 24t117 66l-43 43q-44-35-98-54t-116-19q-145 0-242.5 97.5T140 576q0 145 97.5 242.5T480 916q145 0 242.5-97.5T820 576q0-30-4.5-58.5T802 462l46-46q16 37 24 77t8 83q0 85-31 158t-85 127q-54 54-127 84.5T480 976Zm-59-218L256 592l45-45 120 120 414-414 46 45-460 460Z" />
  </svg>
)

Check.defaultProps = {
  width: '20px',
  height: '20px',
  fill: '#fff',
}

export default Check
