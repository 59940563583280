export const TOGGLE_THEME = 'TOGGLE_THEME'
export const UPDATE_LAYOUT_KEY = 'UPDATE_LAYOUT_KEY'

export const toggleTheme = () => {
  return { type: TOGGLE_THEME }
}

export const updateLayoutKey = () => {
  return { type: UPDATE_LAYOUT_KEY }
}
