import React from 'react'

const Asana = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 251 232" fill={fill}>
    <path d="M179.383 54.8772C179.383 84.8938 155.046 109.25 125.029 109.25C94.9935 109.25 70.6557 84.9127 70.6557 54.8772C70.6557 24.8417 94.9935 0.503906 125.029 0.503906C155.046 0.503906 179.383 24.8417 179.383 54.8772ZM54.3928 122.834C24.3762 122.834 0.0195312 147.172 0.0195312 177.189C0.0195312 207.205 24.3573 231.562 54.3928 231.562C84.4282 231.562 108.766 207.224 108.766 177.189C108.766 147.172 84.4282 122.834 54.3928 122.834ZM195.646 122.834C165.611 122.834 141.273 147.172 141.273 177.208C141.273 207.243 165.611 231.581 195.646 231.581C225.663 231.581 250.02 207.243 250.02 177.208C250.02 147.172 225.682 122.834 195.646 122.834Z" />
  </svg>
)

Asana.defaultProps = {
  width: '24px',
  height: '24px',
  fill: '#fff',
}

export default Asana
