import { UPDATE_MOBILE_OVERVIEW } from '../actions/mobileoverview'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MOBILE_OVERVIEW:
      return updateMobileOverviewApp(state, action.payload)
    default:
      return state
  }
}

const updateMobileOverviewApp = (state, { app, data }) => {
  return { ...state, [app]: data }
}
