import React from 'react'
import { appLinks } from '../../../utils/mobile_app_links'
import * as Icons from 'glob:../../icons/*.js'
import { Tooltip } from '../../Tooltip'

const IconMap = {
    appleStore: <Icons.AppleStore />,
    asana: <Icons.Asana />,
    bugSnag: <Icons.BugSnag />,
    crashlytics: <Icons.Crashlytics />,
    firebase: <Icons.Firebase />,
    github: <Icons.Github />,
    googlePlay: <Icons.GooglePlay />,
    notion: <Icons.Notion />,
    slack: <Icons.Slack />,
}

const TooltipMap = {
    appleStore: 'Apple Connect',
    asana: 'Asana',
    bugSnag: 'BugSnag',
    crashlytics: 'Crashlytics',
    firebase: 'Firebase',
    github: 'Github',
    googlePlay: 'Google Play',
    notion: 'Notion',
    slack: 'Slack',
}

export function MobileStatsLinks({ app, category }) {
    if (!Object.keys(appLinks).includes(app)) return null

    return (
        <div className="mobile-stats--links">
            {Object.entries(appLinks[app][category]).map(([key, link]) =>
                <div key={key} className="mobile-stats--link">
                    <Tooltip tooltip={TooltipMap[key]}>
                        <a href={link} target="_blank">
                            {IconMap[key]}
                        </a>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
