import React from 'react'
import classnames from 'classnames'
import Widget from '../Widget'
import AppIcon from '../AppIcon'
import '../../styles/personapps.scss'

function formatNumber(number) {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k'
  } else {
    return number
  }
}

export default class Personapps extends Widget {
  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [['Tiny', 'tiny'], ['Small', 'small']],
    },
    app: {
      name: 'App',
      type: 'select',
      options: [
        ['Check-ins', 'check-ins'],
        ['Giving', 'giving'],
        ['Groups', 'groups'],
        ['Registrations', 'registrations'],
        ['Calendar', 'calendar'],
        ['Services', 'services'],
      ],
    },
  }

  static defaultSettings = {
    app: 'services',
    size: 'small',
  }

  static getCardData(state, settings) {
    return {
      data: state.data['personapps'],
      settings,
    }
  }

  constructor(props) {
    super(props)
    const { data, settings } = props.data
    if (data && data[props.app] !== undefined) {
      this.state = { total_issues: data[props.app] }
    } else {
      this.state = { total_issues: 'unknown' }
    }
    this.state.settings = settings
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.title !== this.state.title
  }

  get classNames() {
    return classnames('card-front personapps', this.state.settings.size, {
      warning: this.state.total_issues > 50 && this.state.total_issues < 1000,
      danger: this.state.total_issues >= 1000,
    })
  }

  render() {
    const { app } = this.props
    return (
      <div className={this.classNames}>
        <div className="metric">
          <div className="number">
            <AppIcon
              name={app}
              fill={this.classNames.match(/warning|danger/) ? '#fff' : '#ccc'}
              width="24px"
              height="24px"
              className="stats-app__icon"
            />
            {formatNumber(this.state.total_issues)}
          </div>
          out-of-sync people
        </div>
      </div>
    )
  }
}
