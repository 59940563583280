import React, { PropTypes } from 'react'
import capitalize from '../utils/capitalize'

const AppIcon = ({ name, fill, className }) => {
  const props = {
    role: "img",
    width: "50px",
    height: "50px",
    fill,
    className
  }
  const title = <title>{capitalize(name)} Icon</title>

  switch (name) {
    case 'accounts':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <g>
            <circle cx="13" cy="13" r="2.5" />
            <path
              d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M17.8,13
      		c0,0.2,0,0.4,0,0.6l1.4,1.1c0.1,0.1,0.2,0.3,0.1,0.4l-1.3,2.2c-0.1,0.1-0.2,0.2-0.4,0.1l-1.6-0.6c-0.3,0.2-0.7,0.5-1.1,0.6
      		l-0.2,1.7c0,0.2-0.2,0.3-0.3,0.3h-2.6c-0.2,0-0.3-0.1-0.3-0.3l-0.2-1.7c-0.4-0.2-0.7-0.4-1.1-0.6l-1.6,0.6c-0.1,0.1-0.3,0-0.4-0.1
      		l-1.3-2.2c-0.1-0.1,0-0.3,0.1-0.4l1.4-1.1c0-0.2,0-0.4,0-0.6s0-0.4,0-0.6l-1.4-1.1c-0.1-0.1-0.2-0.3-0.1-0.4l1.3-2.2
      		c0.1-0.1,0.2-0.2,0.4-0.1l1.6,0.6c0.3-0.2,0.7-0.5,1.1-0.6l0.2-1.7c0-0.2,0.2-0.3,0.3-0.3h2.6c0.2,0,0.3,0.1,0.3,0.3l0.2,1.7
      		c0.4,0.2,0.7,0.4,1.1,0.6l1.6-0.6c0.1-0.1,0.3,0,0.4,0.1l1.3,2.2c0.1,0.1,0,0.3-0.1,0.4l-1.4,1.1C17.8,12.6,17.8,12.8,17.8,13z"
            />
          </g>
        </svg>
      )
    case 'api':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <g>
            <path d="M17.1,7.7c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3C18.4,8.3,17.8,7.7,17.1,7.7z"
            />
            <path d="M10.9,11.7c-1.3-0.5-2.7,0.1-3.1,1.4c-0.5,1.2,0.1,2.7,1.4,3.1c1.3,0.5,2.7-0.1,3.1-1.4
      		C12.7,13.6,12.1,12.2,10.9,11.7z"/>
            <path d="M12.5,0C2.5,0,0,2.5,0,12.5C0,22.5,2.5,25,12.5,25c10,0,12.5-2.5,12.5-12.5C25,2.5,22.5,0,12.5,0z M14.5,17.9
      		l-1.6,1.3l-1.2-1.5c-0.7,0.3-1.4,0.4-2.2,0.3l-0.7,1.7l-1.9-0.8l0.7-1.7c-0.5-0.4-1-0.9-1.3-1.5l-2,0.3L4,14l1.9-0.3
      		c0-0.4,0.1-0.9,0.3-1.3c0.1-0.4,0.3-0.7,0.5-1l-1.2-1.4l1.6-1.3l1.2,1.4c0.7-0.3,1.4-0.4,2.2-0.3l0.7-1.7l1.9,0.8l-0.7,1.7
      		c0.6,0.4,1.1,1,1.4,1.7l1.8-0.3l0.3,2l-1.8,0.3c0,0.4-0.1,0.7-0.3,1.1c-0.1,0.4-0.3,0.7-0.5,1L14.5,17.9z M19.6,11.5L18.8,11
      		c-0.5,0.4-1,0.6-1.7,0.6c-0.6,0-1.2-0.2-1.7-0.6l-0.9,0.5L13.6,10l0.9-0.5c0-0.2,0-0.3,0-0.5c0-1.1,0.7-2.1,1.7-2.4v-1h1.7v1
      		c1,0.4,1.7,1.3,1.7,2.4c0,0.2,0,0.3,0,0.5l0.9,0.5L19.6,11.5z"/>
          </g>
        </svg>
      )
    case 'check_ins':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M19,10.7l-6.7,6.7
      c-0.6,0.6-1.5,0.6-2.1,0L7,14.2c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l2.1,2.1l5.6-5.6C17.4,8,18.4,8,19,8.6
      C19.6,9.2,19.6,10.1,19,10.7z"
          />
        </svg>
      )
    case 'giving':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            className="st0"
            d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M13.4,17.9
      c-0.2,0.2-0.5,0.2-0.8,0C10.9,16.7,4.8,12,8.3,9c1.2-1,3.1-0.9,4.2,0.3L13,9.7l0.5-0.5c1.1-1.1,3-1.3,4.2-0.3
      C21.2,12,15,16.7,13.4,17.9z"
          />
        </svg>
      )
    case 'groups':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            d="M14.7,10h-3.4c-0.2,0.6-0.7,1-1.3,1.3v3.4c0.6,0.2,1,0.7,1.3,1.3h3.4c0.2-0.6,0.7-1,1.3-1.3v-3.4
  		C15.4,11,15,10.6,14.7,10z M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M19.4,17
  		c0,1.3-1.1,2.4-2.4,2.4c-1,0-1.9-0.6-2.2-1.5h-3.4c-0.4,0.9-1.2,1.5-2.2,1.5c-1.3,0-2.4-1.1-2.4-2.4c0-1,0.6-1.9,1.5-2.2v-3.4
  		C7.2,10.9,6.6,10,6.6,9c0-1.3,1.1-2.4,2.4-2.4c1,0,1.9,0.6,2.2,1.5h3.4C15.1,7.2,16,6.6,17,6.6c1.3,0,2.4,1.1,2.4,2.4
  		c0,1-0.6,1.9-1.5,2.2v3.4C18.8,15.1,19.4,16,19.4,17z"
          />
        </svg>
      )
    case 'music-stand':
    case 'music_stand':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M18.7,15.7L18.7,15.7
      c0,1-1.1,1.9-2.5,2c-1.5,0.2-2.8-0.5-2.9-1.5c-0.1-1,1-1.9,2.5-2.1c0.5-0.1,0.9,0,1.3,0.1V9.4l-6.6,2.1v6h0
      c-0.1,0.9-1.1,1.6-2.5,1.8c-1.5,0.2-2.8-0.5-2.9-1.5c-0.1-1,1-1.9,2.5-2.1c0.5-0.1,0.9,0,1.3,0.1v-5.5C9,10,9.1,9.6,9.4,9.5
      c1.4-0.4,8-2.6,8.9-2.9c0.3-0.1,0.5,0.2,0.5,0.4V15.7z"
          />
        </svg>
      )
    case 'people':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M10.5,7.9
      	c1.4,0,2.6,1.1,2.6,2.6c0,1.4-1.1,2.6-2.6,2.6C9.1,13,8,11.8,8,10.4C8,9,9.1,7.9,10.5,7.9z M14.4,18c0,0.4-0.3,0.7-0.7,0.7H7.4
      	c-0.4,0-0.7-0.3-0.7-0.7v-1.6c0-1.3,1-2.3,2.3-2.3h3.1c1.3,0,2.3,1,2.3,2.3V18z M16.4,9.4c1.1,0,1.9,0.9,1.9,1.9
      	c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9C14.5,10.3,15.3,9.4,16.4,9.4z M19.1,16.4c0,0.4-0.3,0.7-0.7,0.7h-2.5
      	c-0.4,0-0.7-0.3-0.7-0.7v-0.1c0-0.4-0.1-0.8-0.3-1.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
      	c0.3-0.4,0.7-0.6,1.1-0.6h1.9c0.8,0,1.4,0.6,1.4,1.4V16.4z"
          />
        </svg>
      )
    case 'platform':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path d="M13,0C2.6,0,0,2.6,0,13s2.6,13,13,13s13-2.6,13-13S23.4,0,13,0z M6.6,9.3L12.7,6c0.2-0.1,0.4-0.1,0.5,0l6.1,3.3
        c0.2,0.1,0.2,0.3,0,0.4L13.3,13c-0.2,0.1-0.4,0.1-0.5,0L6.6,9.7C6.4,9.6,6.4,9.4,6.6,9.3z M19.4,16.7L13.3,20
        c-0.2,0.1-0.4,0.1-0.6,0l-6.1-3.3c-0.2-0.1-0.2-0.3,0-0.4l1.4-0.7l4.7,2.5c0.3,0.2,0.5,0.2,0.7,0l4.7-2.5l1.4,0.7
        C19.6,16.4,19.6,16.6,19.4,16.7z M19.4,13.2l-6.1,3.3c-0.2,0.1-0.4,0.1-0.6,0l-6.1-3.3c-0.2-0.1-0.2-0.3,0-0.4l1.2-0.7l4.8,2.5
        c0.3,0.1,0.5,0.1,0.7,0l4.8-2.5l1.2,0.7C19.6,12.8,19.6,13.1,19.4,13.2z"/>
        </svg>
      )
    case 'pm':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <g>
            <path d="M12.9-0.1c-10.4,0-13,2.6-13,13s2.6,13,13,13s13-2.6,13-13S23.3-0.1,12.9-0.1z M17,15.1h-0.9c0,1.5-1.2,2.7-2.7,2.7H8
      		c-1.5,0-2.7-1.2-2.7-2.7V8.6C5.3,8.3,5.6,8,5.9,8h11c2,0,3.6,1.6,3.6,3.6S18.9,15.1,17,15.1z"/>
            <path d="M17,9.7h-0.9v3.6H17c1,0,1.8-0.8,1.8-1.8S17.9,9.7,17,9.7z" />
          </g>
        </svg>
      )
    case 'publishing':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path d="M13,0C2.6,0,0,2.6,0,13s2.6,13,13,13s13-2.6,13-13S23.4,0,13,0z M9.5,17.7c0,0.4-0.3,0.6-0.6,0.6H7.6C7.2,18.3,7,18,7,17.7
    		V8.3C7,8,7.2,7.7,7.6,7.7h1.2c0.4,0,0.6,0.3,0.6,0.6V17.7z M19,17.7c0,0.4-0.3,0.6-0.6,0.6h-6.3c-0.4,0-0.6-0.3-0.6-0.6v-1.2
    		c0-0.4,0.3-0.6,0.6-0.6h6.3c0.4,0,0.6,0.3,0.6,0.6L19,17.7L19,17.7z M19,13.2c0,0.4-0.3,0.6-0.6,0.6h-6.3c-0.4,0-0.6-0.3-0.6-0.6
    		V8.3c0-0.4,0.3-0.6,0.6-0.6h6.3C18.7,7.7,19,8,19,8.3L19,13.2L19,13.2z"/>
        </svg>
      )
    case 'registrations':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13s13-2.6,13-13C26,2.6,23.4,0,13,0z M14.2,17.6l-2.6,2.6
        c-0.3,0.3-0.7,0.3-0.9,0l-0.9-0.9c-0.1-0.1-0.1-0.2,0-0.4c0.4-0.7,0.3-1.7-0.3-2.4c-0.6-0.6-1.6-0.7-2.4-0.3c-0.1,0.1-0.3,0.1-0.4,0
        l-0.9-0.9c-0.3-0.3-0.3-0.7,0-0.9l2.6-2.6c0.1-0.1,0.2-0.1,0.3,0l5.5,5.5C14.3,17.4,14.3,17.5,14.2,17.6z M20.2,11.6l-4.7,4.7
        c-0.1,0.1-0.2,0.1-0.3,0l-5.5-5.5c-0.1-0.1-0.1-0.2,0-0.3l4.7-4.7c0.3-0.3,0.7-0.3,0.9,0l0.9,0.9c0.1,0.1,0.1,0.2,0,0.3
        c-0.4,0.7-0.3,1.7,0.3,2.3c0.6,0.6,1.6,0.7,2.3,0.3c0.1-0.1,0.3-0.1,0.3,0l0.9,0.9C20.4,10.9,20.4,11.3,20.2,11.6z"/>
        </svg>
      )
    case 'calendar':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <g>
            <path d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13c10.4,0,13-2.6,13-13C26,2.6,23.4,0,13,0z M19.2,18.3
        c0,0.4-0.3,0.8-0.8,0.8H7.5c-0.4,0-0.8-0.3-0.8-0.8V9.1c0-0.4,0.3-0.8,0.8-0.8h1.2V7.4C8.7,7,9,6.7,9.4,6.7h0.3
        c0.4,0,0.7,0.3,0.7,0.7v0.9h5V7.4c0-0.4,0.3-0.7,0.7-0.7h0.3c0.4,0,0.7,0.3,0.7,0.7v0.9h1.2c0.4,0,0.8,0.3,0.8,0.8V18.3z"/>
            <rect x="8.7" y="11.7" width="8.5" height="5.4" />
          </g>
        </svg>
      )
    case 'services':
      return (
        <svg {...props} viewBox="0 0 26 26">
          {title}
          <path
            d="M13,0C2.6,0,0,2.6,0,13c0,10.4,2.6,13,13,13s13-2.6,13-13C26,2.6,23.4,0,13,0z M7.1,18.9
    		c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4S7.9,18.9,7.1,18.9z M7.1,14.4c-0.8,0-1.4-0.6-1.4-1.4
    		c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C8.5,13.8,7.9,14.4,7.1,14.4z M7.1,9.9c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
    		s1.4,0.6,1.4,1.4S7.9,9.9,7.1,9.9z M19.5,18.1c0,0.3-0.3,0.6-0.6,0.6h-8.3c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h8.3
    		c0.3,0,0.6,0.3,0.6,0.6V18.1z M19.5,13.6c0,0.3-0.3,0.6-0.6,0.6h-8.3c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h8.3
    		c0.3,0,0.6,0.3,0.6,0.6V13.6z M19.5,9.2c0,0.3-0.3,0.6-0.6,0.6h-8.3c-0.3,0-0.6-0.3-0.6-0.6V7.9c0-0.3,0.3-0.6,0.6-0.6h8.3
    		c0.3,0,0.6,0.3,0.6,0.6V9.2z"
          />
        </svg>
      )
    case 'church_center_app':
      return (
        <svg {...props} viewBox="0 0 16 16">
          {title}
          <path d="M8.00014 0.27002C1.81894 0.27002 0.270142 1.81582 0.270142 8.00002C0.270142 14.1842 1.81894 15.73 8.00014 15.73C14.1813 15.73 15.7301 14.1812 15.7301 8.00002C15.7301 1.81882 14.1843 0.27002 8.00014 0.27002ZM11.0288 11.6995C11.0293 11.7683 11.0025 11.8345 10.9544 11.8836C10.9062 11.9327 10.8406 11.9607 10.7718 11.9615L9.25198 11.9495C9.1832 11.9503 9.11691 11.9238 9.06762 11.8758C9.01833 11.8279 8.99007 11.7623 8.98901 11.6935C8.98901 11.6935 8.98901 11.5146 8.98901 10.2937C8.973 9.90335 8.87696 9.52042 8.70686 9.16869C8.53675 8.81696 8.29621 8.50392 8.00014 8.24899C7.70262 8.50424 7.4609 8.81809 7.29007 9.17092C7.11925 9.52376 7.02298 9.90802 7.00727 10.2997C7.00727 11.5146 7.00727 11.6995 7.00727 11.6995C7.00688 11.7335 6.99979 11.7671 6.98641 11.7984C6.97304 11.8297 6.95363 11.858 6.9293 11.8818C6.90498 11.9056 6.87621 11.9243 6.84464 11.9369C6.81307 11.9496 6.77931 11.9559 6.7453 11.9555L5.2245 11.9675C5.19045 11.9672 5.15679 11.9602 5.12546 11.9469C5.09413 11.9336 5.06575 11.9142 5.04196 11.8898C5.01816 11.8655 4.99942 11.8367 4.98682 11.805C4.97422 11.7734 4.968 11.7396 4.96853 11.7055C4.87644 9.98679 4.87111 8.26449 4.95254 6.54521L7.73318 4.14552C7.80636 4.08058 7.9008 4.04471 7.99864 4.04471C8.09648 4.04471 8.19093 4.08058 8.26411 4.14552L11.0417 6.54521C11.1245 8.26244 11.1201 9.98275 11.0288 11.6995Z" />
        </svg>

      )
    case 'headcounts':
      return (
        <svg {...props} viewBox="0 0 42 42">
          {title}
          <path fill={fill} d="M0,20.9838006 C0,4.3723606 4.10879,0.0873176 20.4599,4.99600361e-16 L21.5109,4.99600361e-16 C37.8604,0.0873176 41.9692,4.3723606 41.9692,20.9838006 C41.9692,37.7714006 37.7714,41.9692006 20.9854,41.9692006 C4.19773,41.9692006 0,37.7714006 0,20.9838006 Z M27.8702,23.4355006 C29.3077,23.4355006 30.4736,22.2696006 30.4736,20.8321006 C30.4736,19.3930006 29.3077,18.2287006 27.8702,18.2287006 L23.7129,18.2287006 L23.7129,14.1135006 C23.7129,12.6711006 22.5455,11.5020006 21.1031,11.5020006 C19.6607,11.5020006 18.4917,12.6711006 18.4917,14.1135006 L18.4917,18.2287006 L14.0999,18.2287006 C12.6608,18.2287006 11.4965,19.3930006 11.4965,20.8305006 C11.4965,22.2696006 12.6608,23.4355006 14.0999,23.4355006 L18.4917,23.4355006 L18.4917,27.8628006 C18.4917,29.3003006 19.6591,30.4662006 21.0966,30.4662006 L21.1096,30.4662006 C22.5487,30.4662006 23.7129,29.3003006 23.7129,27.8628006 L23.7129,23.4355006 L27.8702,23.4355006 Z" transform="translate(0 .072)"/>
        </svg>

      )
  }

}

AppIcon.propTypes = {
  name: PropTypes.string.isRequired,
  frame: PropTypes.bool,
  fill: PropTypes.string,
  className: PropTypes.string,
}

AppIcon.defaultProps = {
  fill: '#fff',
  frame: true,
}

export default AppIcon
