export const ADD_WIDGET = 'ADD_WIDGET'
export const REMOVE_WIDGET = 'REMOVE_WIDGET'
export const REMOVE_WIDGETS = 'REMOVE_WIDGETS'
export const UPDATE_WIDGET = 'UPDATE_WIDGET'
export const UPDATE_SETTING = 'UPDATE_SETTING'

export const addWidget = (widget, type, id, settings = {}) => {
  return {
    type: ADD_WIDGET,
    payload: {
      id,
      type,
      settings: { ...widget.defaultSettings, ...settings },
      height: widget.height,
      width: widget.width,
    },
  }
}

export const removeWidget = id => {
  return {
    type: REMOVE_WIDGET,
    payload: id,
  }
}

export const removeWidgets = () => {
  return {
    type: REMOVE_WIDGETS,
  }
}

export const updateWidget = (id, properties) => {
  return {
    type: UPDATE_WIDGET,
    payload: { id, properties },
  }
}

export const updateSetting = (id, key, value) => {
  return {
    type: UPDATE_SETTING,
    payload: { id, key, value },
  }
}
