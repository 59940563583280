import React, { Component, createElement } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Sidebar from './Sidebar'
import Grid from './Grid'
import Footer from './Footer'

import '../styles/general.scss'
import '../styles/dark-theme.scss'

class Dashboard extends Component {
  static propTypes = {
    data: React.PropTypes.object,
    widgets: React.PropTypes.array,
    debugMode: React.PropTypes.bool,
    dispatch: React.PropTypes.func.isRequired,
  }

  static defaultProps = {
    debugMode: false,
  }

  componentWillMount() {
    this._connect()
    this._setTheme()
  }

  componentWillUnmount() {
    this._source.removeEventListener('open', this._handleOpen)
    this._source.removeEventListener('error', this._handleErrors)
    this._source.removeEventListener('message', this._handleMessages)
  }

  componentDidUpdate() {
    this._setTheme()
  }

  _connect() {
    this._source = new window.EventSource('/events')
    this._source.addEventListener('open', this._handleOpen)
    this._source.addEventListener('error', this._handleErrors)
    this._source.addEventListener('message', this._handleMessages)
    this._setReconnectTimeout()
  }

  _disconnect() {
    if (this._source) {
      this._source.close()
      this._source = null
    }
  }

  _setReconnectTimeout() {
    clearTimeout(this._reconnectTimeout)
    this._reconnectTimeout = setTimeout(() => {
      this._connect()
    }, 30000)
  }

  _setTheme() {
    const themeClass = classNames(
      { 'light-theme': this.props.settings.defaultTheme },
      { 'dark-theme': !this.props.settings.defaultTheme }
    )

    document.body.className = themeClass
  }

  _handleOpen = e => {
    if (this.props.debugMode) {
      console.log('Connection opened', e)
    }
  }

  _handleMessages = e => {
    this._setReconnectTimeout()

    const { debugMode, dispatch } = this.props
    const payload = JSON.parse(e.data)

    if (debugMode) {
      console.log('Received data for dashboards', payload)
    }

    if (payload) {
      dispatch({
        type: payload.id,
        dashboard: this.props.dashboard.id,
        payload,
      })
    }
  }

  _handleErrors = e => {
    const { debugMode } = this.props
    if (debugMode) {
      console.log('Connection error', e)
    }
    this._disconnect()
  }

  render() {
    return (
      <div>
        <Sidebar />
        <Grid />
        <Footer />
      </div>
    )
  }
}

export default connect(state => state)(Dashboard)
