import React from 'react'
import capitalize from '../../utils/capitalize'
import appNames from '../../utils/app_names'
import classNames from 'classnames'
import AppIcon from '../AppIcon'
import CrossFade from '../CrossFade'
import Widget from '../Widget'

import * as Icons from 'glob:../icons/*.js'

import '../../styles/alerts.scss'

export default class Alerts extends Widget {
  static settings = appNames.reduce(
    (map, app) => {
      map[`${app}_alerts`] = {
        name: capitalize(app),
        type: 'checkbox',
        settings: (() => {
          let childSettings = {}
          childSettings[`${app}_error_rate`] = {
            name: 'Error Rate',
            type: 'number',
            step: 1,
            max: 100,
            min: 1,
          }
          childSettings[`${app}_failed_jobs`] = {
            name: 'Failed Jobs',
            type: 'number',
            step: 1,
            max: 100,
            min: 1,
          }
          childSettings[`${app}_response_time`] = {
            name: 'Response Time',
            type: 'number',
            step: 5,
            max: 500,
            min: 50,
          }
          return childSettings
        })(),
      }
      return map
    },
    {
      size: {
        name: 'Size',
        type: 'select',
        options: [['Small', 'small'], ['Normal', 'normal']],
      },
    }
  )

  static dataSource = 'stats'

  static defaultSettings = appNames.reduce(
    (map, app) => {
      map[`${app}_alerts`] = app === 'services' ? true : false
      map[`${app}_error_rate`] = 25
      map[`${app}_failed_jobs`] = 10
      map[`${app}_response_time`] = 250
      return map
    },
    { size: 'normal' }
  )

  static defaultProps = {
    data: appNames.reduce((map, app) => {
      map[app] = { metrics: {}, jobs: {} }
      return map
    }, {}),
  }

  constructor(props) {
    super(props)
    this.state = { currentIndex: undefined }
  }

  componentWillMount() {
    this.loadNextApp()
  }

  componentDidMount() {
    this.interval = setInterval(() => this.loadNextApp(), 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.currentIndex !== this.state.currentIndex
  }

  get data() {
    return { ...this.constructor.defaultProps.data, ...this.props.data }
  }

  get activeApps() {
    return appNames.filter(app => this.props[`${app}_alerts`])
  }

  get appsWithErrors() {
    return this.activeApps.filter(app => this.alertsForApp(app).length)
  }

  get currentApp() {
    return this.appsWithErrors[this.state.currentIndex]
  }

  alertsForApp(app) {
    try {
      const {
        metrics: { error_rate, response_time },
        jobs: { current_failed },
      } = this.data[app]
      let alerts = []
      if (parseFloat(error_rate) >= this.props[`${app}_error_rate`]) {
        alerts.push(`Error Rate: ${error_rate}`)
      }
      if (response_time >= this.props[`${app}_response_time`]) {
        alerts.push(`Response Time: ${response_time}`)
      }
      if (current_failed >= this.props[`${app}_failed_jobs`]) {
        alerts.push(`Failed Jobs: ${current_failed}`)
      }
      return alerts
    } catch (e) {
      console.log(`Error Building Alerts for ${capitalize(app)}: ${e}`)
      return []
    }
  }

  loadNextApp() {
    let newIndex
    if (!this.appsWithErrors.length) {
      newIndex = undefined
    } else {
      if (this.state.currentIndex === undefined) {
        newIndex = 0
      } else {
        newIndex = this.state.currentIndex + 1
        if (!this.appsWithErrors[newIndex]) newIndex = 0
      }
    }
    this.setState({ currentIndex: newIndex })
  }

  renderError() {
    const alerts = this.alertsForApp(this.currentApp)
    const appKey = this.currentApp

    return (
      <div className="app-alert" key={appKey}>
        <div className="app-container danger">
          <AppIcon name={appKey} className="stats-app__icon" fill="#c3c3c3" />
        </div>
        {alerts && (
          <ul>
            <li>{capitalize(this.currentApp)}</li>
            {alerts.map((alert, index) => (
              <li key={index}>{alert}</li>
            ))}
            <li className="app-alert--on-call">On Call: <strong>{this.props.data[appKey].onCall}</strong></li>
          </ul>
        )}
      </div>
    )
  }

  renderNoErrors() {
    return (
      <div className="app-alert">
        <div className="app-container pico-alert-container">
          <Icons.Pico />
        </div>
        <h3>All Good</h3>
      </div>
    )
  }

  render() {
    const classes = classNames('alert-card-container', {
      danger: this.currentApp,
    })
    return (
      <div className={classes}>
        <div className="no-header-icon">
          <Icons.Alerts />
        </div>
        <div className="app-alert-container">
          <CrossFade>
            {this.currentApp ? this.renderError() : this.renderNoErrors()}
          </CrossFade>
        </div>
      </div>
    )
  }
}
