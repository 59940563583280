export default {
  SPACE: 32,
  BACKSPACE: 8,
  ENTER: 13,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  J: 74,
  K: 75,
  S: 83,
  COMMAND: 91,
  PERIOD: 190,
}
