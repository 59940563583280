import React from 'react'

const Move = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <polygon points="81.9 46.65 77.39 50.99 81.56 55 61 55 61 34.83 65.2 39 69.73 34.49 57.88 22.64 46.03 34.49 50.18 39 54 34.83 54 55 34.22 55 38.39 50.99 33.88 46.57 22.03 58.46 33.88 70.33 38.39 66 34.22 62 54 62 54 82.17 50.19 78 45.86 82.51 57.8 94.36 69.7 82.51 65.18 78 61 82.17 61 62 81.56 62 77.39 66.01 81.9 70.43 93.75 58.54 81.9 46.65" />
  </svg>
)

Move.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#fff',
}

export default Move
