import React from 'react'

const StarOutline = ({ width, height, fill }) => (
    <svg width={width} height={height} viewBox="0 96 960 960" fill={fill}>
        <path d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
    </svg>
)

StarOutline.defaultProps = {
    width: '16px',
    height: '16px',
    fill: '#fff',
}

export default StarOutline
