import React, { Component } from 'react'

export default class Widget extends Component {
  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [['Small', 'small'], ['Normal', 'normal']],
    },
  }
  static defaultSettings = {
    size: 'normal',
  }
  static height = 2
  static width = 3
  static defaultProps = { data: {} }

  static widgetProperties(newProps) {
    const { size } = newProps
    switch (size) {
      case 'tiny':
        return {
          height: 1,
          width: 1,
        }
        break
      case 'small':
        return {
          height: 1,
          width: 3,
        }
        break
      default:
        return {
          height: 2,
          width: 3,
        }
    }
  }

  static getCardData(state, settings) {
    return state.data[this.dataSource]
  }

  render() {
    return <noscript />
  }
}
