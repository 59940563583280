import React from 'react'
import MapHeader from '../MapHeader'
import MapBody from '../MapBody'
import Widget from '../Widget'
import { updateSetting } from '../../actions/widgets'

import '../../styles/map.scss'

export default class Map extends Widget {
  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [
        ['Small', 'small'],
        ['Tall', 'tall'],
        ['Wide', 'wide'],
        ['Big', 'big'],
        ['Full', 'full'],
      ],
    },
    zoom: {
      name: 'Map Zoom',
      type: 'number',
      step: 1,
      max: 10,
      min: 2,
    },
    app: {
      name: 'Map',
      type: 'select',
      options: [
        ['Check Ins', 'check_ins'],
        ['Giving', 'giving'],
        ['People', 'people'],
        ['Registrations', 'registrations'],
        ['Calendar', 'calendar'],
        ['Services', 'services'],
      ],
    },
    usersVisible: {
      name: 'Display Active User Count',
      type: 'checkbox',
    },
    citiesVisible: {
      name: 'Display Active Cities',
      type: 'checkbox',
    },
    countriesVisible: {
      name: 'Display Active Countries',
      type: 'checkbox',
    },
  }

  static defaultSettings = {
    app: 'check_ins',
    size: 'big',
    usersVisible: true,
    citiesVisible: true,
    countriesVisible: true,
    zoom: 3,
    lat: 42.42072847,
    lng: -99.3696875,
  }

  static defaultProps = {
    data: {
      markers: {},
      users: 0,
      cities: 0,
      countries: 0,
    },
  }

  static height = 4
  static width = 6

  static widgetProperties(newProps) {
    const { size } = newProps
    switch (size) {
      case 'small':
        return {
          height: 2,
          width: 3,
        }
        break
      case 'tall':
        return {
          height: 4,
          width: 3,
        }
        break
      case 'wide':
        return {
          height: 2,
          width: 6,
        }
        break
      case 'big':
        return {
          height: 4,
          width: 6,
        }
        break
      case 'full':
        return {
          height: 6,
          width: 9,
        }
        break
      default:
        return {
          height: 4,
          width: 6,
        }
    }
  }

  static getCardData(state, settings) {
    const {
      data,
      settings: { defaultTheme },
    } = state
    return { ...data['active_users'][settings.app], defaultTheme }
  }

  get data() {
    return { ...this.constructor.defaultProps.data, ...this.props.data }
  }

  saveMapState(zoom, center) {
    const { dispatch, id } = this.props
    dispatch(updateSetting(id, 'zoom', zoom))
    dispatch(updateSetting(id, 'lat', center.lat()))
    dispatch(updateSetting(id, 'lng', center.lng()))
  }

  render() {
    const {
      usersVisible,
      citiesVisible,
      countriesVisible,
      app,
      zoom,
      lat,
      lng,
    } = this.props
    const { markers, users, cities, countries, defaultTheme } = this.data
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <MapHeader
          app={app}
          users={users}
          cities={cities}
          countries={countries}
          usersVisible={usersVisible}
          citiesVisible={citiesVisible}
          countriesVisible={countriesVisible}
        />
        <MapBody
          markers={markers}
          defaultTheme={defaultTheme}
          zoom={zoom}
          lat={lat}
          lng={lng}
          onMapChange={(zoom, center) => this.saveMapState(zoom, center)}
        />
      </div>
    )
  }
}
