import { TOGGLE_THEME, UPDATE_LAYOUT_KEY } from '../actions/settings'
import { ADD_WIDGET, REMOVE_WIDGETS } from '../actions/widgets'
import { UPDATED_DASHBOARD } from '../actions/dashboard'
import { get } from 'lodash'

const initialState = {
  defaultTheme: true,
  widgetCount: 0,
  gridUpdatedAt: 0,
}

const timeString = () => new Date().getTime().toString()

export default (state = initialState, action) => {
  const widgetCount = get(state, 'widgets', []).length
  switch (action.type) {
    case UPDATED_DASHBOARD:
      const { data } = action.payload || { data: {} }
      return data.id === action.dashboard
        ? {
            ...state,
            defaultTheme: data.config.settings.defaultTheme,
            gridUpdatedAt: timeString(),
          }
        : state
    case ADD_WIDGET:
      return {
        ...state,
        widgetCount: widgetCount + 1,
        gridUpdatedAt: timeString()
      }
    case UPDATE_LAYOUT_KEY:
      return { ...state, gridUpdatedAt: timeString() }
    case REMOVE_WIDGETS:
      return { ...state, widgetCount: 0 }
    case TOGGLE_THEME:
      return { ...state, defaultTheme: !state.defaultTheme }
    default:
      return state
  }
}
