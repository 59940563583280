import React from 'react'
import { Motion, spring } from 'react-motion'

class AnimatedValue extends React.Component {
  render() {
    return (
      <Motion style={{ x: spring(this.props.value) }}>
        {({ x }) => <span>{Math.round(x)}</span>}
      </Motion>
    )
  }
}

export default AnimatedValue
