import React from 'react'

const Close = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <polygon
      points="92.644,29.21 86.79,23.357 58,52.146 29.21,23.357 23.356,29.21 52.146,58 23.356,86.79 29.21,92.643 58,63.854
			86.79,92.643 92.644,86.79 63.854,58"
    />
  </svg>
)

Close.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#c3c3c3',
}

export default Close
