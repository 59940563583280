import * as Icons from 'glob:./icons/*.js'
import * as widgetTypes from 'glob:./widgets/*.js'
import React, { Component, createElement } from 'react'
import { Motion, spring } from 'react-motion'
import { connect } from 'react-redux'
import uuidv4 from 'uuid/v4'
import { saveDashboard } from '../actions/dashboard'
import { toggleTheme } from '../actions/settings'
import { toggleSidebar } from '../actions/status'
import { addWidget, removeWidgets } from '../actions/widgets'
import keyCodes from '../utils/key_code'

import '../styles/sidebar.scss'

const typeIcons = {
  Alerts: Icons.Bell,
  Calendar: Icons.Calendar,
  Clock: Icons.Clock,
  Map: Icons.Marker,
  Oncall: Icons.Oncall,
  Personapps: Icons.Person,
  Sonos: Icons.Song,
  Stats: Icons.Layout,
  MobileOverview: Icons.Apps,
  MobileStats: Icons.Layout,
  Support: Icons.Cog,
  Twitter: Icons.Twitter,
}

function formatWidgetName(name) {
  return `${name[0].toLowerCase()}${name.slice(1)}`
}

class Sidebar extends Component {
  static defaultProps = {
    sidebarVisible: false,
  }

  componentDidMount() {
    window.addEventListener('keydown', this._handleWindowKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this._handleWindowKeyDown)
  }

  _handleSave() {
    const { dashboard, widgets, layout, defaultTheme, dispatch } = this.props
    const config = { widgets, layout, settings: { defaultTheme: defaultTheme } }

    // Need to implement Thunk instead of passing dispatch here
    dispatch(saveDashboard(dashboard.id, config, dispatch))
  }

  _handleWindowKeyDown = e => {
    if (
      e.keyCode === keyCodes.S &&
      (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)
    ) {
      e.preventDefault()
      this._handleSave()
    }
    if (e.keyCode === keyCodes.SPACE) {
      e.preventDefault()
      this.props.dispatch(toggleSidebar())
    }
    if (e.keyCode === keyCodes.ESC && this.props.sidebarVisible) {
      e.preventDefault()
      this.props.dispatch(toggleSidebar())
    }
  }

  _addWidget = type => {
    const widget = widgetTypes[type]
    const { dispatch } = this.props
    const id = uuidv4()

    dispatch(addWidget(widget, formatWidgetName(type), `${type.toLowerCase()}-${id}`))
  }

  _resetLayout = () => {
    this.props.dispatch(removeWidgets())
  }

  render() {
    const { sidebarVisible, defaultTheme, dispatch } = this.props

    return (
      <Motion style={{ x: spring(sidebarVisible ? 0 : -100) }}>
        {({ x }) => (
          <div
            className="controls sidebar"
            style={{ transform: `translate3d(${x}%, 0, 0)` }}
          >
            <div className="sidebar-header">
              <h1>Widgets</h1>
              <button className="btn" onClick={() => dispatch(toggleSidebar())}>
                <Icons.Close fill="#c4c4c4" />
              </button>
            </div>
            <div className="widget-container">
              {Object.keys(widgetTypes).map(type => {
                return (
                  <button
                    key={type}
                    className="sidebar-widget"
                    onClick={this._addWidget.bind(this, type)}
                  >
                    {createElement(typeIcons[type], {
                      className: 'sidebar-widget__icon',
                      fill: '#c3c3c3'
                    })}
                    <span className="widget-type">{type}</span>
                  </button>
                )
              })}
            </div>
            <h3>Settings</h3>
            <ul>
              <li>
                Display Mode
                <div className="toggleWrapper">
                  <input
                    type="checkbox"
                    className="dn"
                    id="dn"
                    checked={!defaultTheme}
                    onChange={() => dispatch(toggleTheme())}
                  />
                  <label htmlFor="dn" className="toggle">
                    <span className="toggle__handler" />
                    <span className="toggle-icon toggle-icon-left">
                      <Icons.Moon />
                    </span>
                    <span className="toggle-icon toggle-icon-right">
                      <Icons.Sun />
                    </span>
                  </label>
                </div>
              </li>
            </ul>
            <div className="spacer">
              <button className="btn--save" onClick={() => this._handleSave()}>
                Save
              </button>
            </div>
            <button className="btn btn--clear" onClick={this._resetLayout}>
              Clear Dashboard
            </button>
          </div>
        )}
      </Motion>
    )
  }
}

export default connect(state => ({
  dashboard: state.dashboard,
  widgets: state.widgets,
  layout: state.layout,
  sidebarVisible: state.status.sidebarVisible,
  widgetCount: state.settings.widgetCount,
  defaultTheme: state.settings.defaultTheme,
}))(Sidebar)
