export const appLinks = {
    services: {
        general: {
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/C024LSG51',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.PlanningCenter&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/planning-center-android-2',
            asana: 'https://app.asana.com/0/1203159608098442/1203159608098442',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/planning-center-services/id327370808',
            github: 'https://github.com/planningcenter/services-ios',
            asana: 'https://app.asana.com/0/1203152862692398/1203152862692398',
        }
    },
    music_stand: {
        general: {
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/C024LSG51',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.musicstand&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/Music-Stand-Android',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/planning-center-services/id327370808',
            github: 'https://github.com/planningcenter/music-stand-ios',
        }
    },
    people: {
        general: {
            asana: 'https://app.asana.com/0/1203168367643658/1203168367643658',
            bugsnag: 'google.com',
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/C08H3U5CP',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.people&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/people-react-native',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/planning-center-people/id1029697457',
            github: 'https://github.com/planningcenter/people-react-native',
        }
    },
    church_center_app: {
        general: {
            asana: 'https://app.asana.com/0/1203119596857793/1203119596857793',
            bugsnag: 'google.com',
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/C8DP1A64R',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/ChurchCenterApp',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/church-center-app/id1357742931',
            github: 'https://github.com/planningcenter/ChurchCenterApp',
        }
    },
    check_ins: {
        general: {
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/CARAY426R',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/ChurchCenterApp',
            asana: 'https://app.asana.com/0/1203181261870794/1203181261870794',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/church-center-app/id1357742931',
            github: 'https://github.com/planningcenter/ChurchCenterApp',
            asana: 'https://app.asana.com/0/1203181261870845/1203181261870845',
        }
    },
    calendar: {
        general: {
            asana: 'https://app.asana.com/0/1204412244533399/1204412244533399',
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/CEYGLCGE9',
        },
        android: {
            googlePlay: 'https://play.google.com/store/search?q=calendar pco&c=apps&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/calendar-mobile',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/planning-center-calendar/id784966220',
            github: 'https://github.com/planningcenter/calendar-mobile',
        }
    },
    headcounts: {
        general: {
            asana: 'https://app.asana.com/0/1203181261870860/1203181261870860',
            firebase: 'https://console.firebase.google.com/',
            slack: 'https://pco.slack.com/archives/CES33E9KN',
        },
        android: {
            googlePlay: 'https://play.google.com/store/apps/details?id=com.ministrycentered.headcounts&hl=en_US&gl=US',
            github: 'https://github.com/planningcenter/calendar-mobile',
        },
        ios: {
            appleStore: 'https://apps.apple.com/us/app/planning-center-headcounts/id1215339253',
            github: 'https://github.com/planningcenter/calendar-mobile',
        }
    }
}
