import React from 'react'
import { connect } from 'react-redux'
import { toggleSidebar } from '../actions/status'

import * as Icons from 'glob:./icons/*.js'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <button
          className={!this.props.widgets.length ? 'add-btn-on' : 'add-btn'}
          onClick={() => this.props.dispatch(toggleSidebar())}
        >
          <Icons.AddBtn />
        </button>
      </div>
    )
  }
}

export default connect(state => {
  return {
    widgets: state.widgets
  }
})(Footer)
