export default [
  'accounts',
  'api',
  'check_ins',
  'giving',
  'groups',
  'people',
  'registrations',
  'calendar',
  'services',
]
