import { UPDATE_SUPPORT } from '../actions/support'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUPPORT:
      const { team, data } = action.payload
      return { ...state, [team]: { ...state[team], ...data } }
    default:
      return state
  }
}
