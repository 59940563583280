import React from 'react'

const Song = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M85.16,23.05a7.09,7.09,0,0,0-7.4.36L59.71,36H39A10.29,10.29,0,0,0,29,46.42V68.58A10.29,10.29,0,0,0,39,79H59.71l18,12.59a7.05,7.05,0,0,0,4.12,1.24A7.23,7.23,0,0,0,89,85.6V29.4A7.11,7.11,0,0,0,85.16,23.05ZM37,68.58V46.42A2.3,2.3,0,0,1,39,44H54V71H39A2.3,2.3,0,0,1,37,68.58ZM81,84L62,70.07V44.93L81,31V84Z" />
  </svg>
)

Song.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Song
