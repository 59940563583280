import React from 'react'
import * as Icons from 'glob:../../icons/*.js'
import { formatCount, formatPercent } from '../../../utils/format'

export function MobileStatsInfo({ props, data }) {
    const {
        appVersionVisible,
        appRatingVisible,
        appDownloadsVisible,
        appCrashVisible,
    } = props
    const children = []

    if (appVersionVisible) {
        children.push({
            label: 'Version',
            value: data.version
        })
    }
    if (appRatingVisible) {
        children.push({
            label: 'Rating',
            icon: <Icons.Star />,
            value: `${Number(data.rating).toFixed(1) || 0} (${formatCount(Number(data.rating_count) || 0, 1)})`
        })
    }
    if (appDownloadsVisible) {
        children.push({
            label: 'Downloads',
            value: formatCount(data.platform_newUsers || 0, 2)
        })
    }
    if (appCrashVisible) {
        children.push({
            label: 'Crash Free %',
            value: formatPercent(data.crashFreeUsersRate || 0)
        })
    }

    return (
        <ul>
            {children.slice(0, 4).map(item =>
                <li className='mobile-stats--row' key={`stat-${item.label}`}>
                    <span>{item.label}</span>
                    <div>
                        {Boolean(item.icon) && item.icon}
                        {item.value}
                    </div>
                </li>
            )}
        </ul>
    )
}
