import React from 'react'
import AppIcon from '../AppIcon'
import Widget from '../Widget'
import * as Icons from 'glob:../icons/*.js'

import '../../styles/oncall.scss'

const APPS = [
  'accounts',
  'api',
  'check_ins',
  'giving',
  'groups',
  'people',
  'platform',
  'pm',
  'publishing',
  'registrations',
  'calendar',
  'services',
]

export default class Oncall extends Widget {
  static config = {
    accounts: {
      appColor: '#4B7ADC',
      fillColor: '#48a6dd',
    },
    api: {
      appColor: '#539BCA',
      fillColor: '#3b9ccf',
    },
    check_ins: {
      appColor: '#816393',
      fillColor: '#8a6bcb',
    },
    giving: {
      appColor: '#EEC565',
      fillColor: '#ffdf91',
    },
    groups: {
      appColor: '#EC7D50',
      fillColor: '#ffb48b',
    },
    people: {
      appColor: '#4B7ADC',
      fillColor: '#81a3f0',
    },
    platform: {
      appColor: '#4B7ADC',
      fillColor: '#3b9ccf',
    },
    pm: {
      appColor: '#4B7ADC',
      fillColor: '#3b9ccf',
    },
    publishing: {
      appColor: '#6B6B74',
      fillColor: '#313A44',
    },
    registrations: {
      appColor: '#59928D',
      fillColor: '#74ddd4',
    },
    calendar: {
      appColor: '#BF513B',
      fillColor: '#ffaca3',
    },
    services: {
      appColor: '#739446',
      fillColor: '#82c069',
    },
  }

  static settings = {
    accountsVisible: {
      name: 'Accounts',
      type: 'checkbox',
    },
    apiVisible: {
      name: 'API',
      type: 'checkbox',
    },
    check_insVisible: {
      name: 'Check Ins',
      type: 'checkbox',
    },
    givingVisible: {
      name: 'Giving',
      type: 'checkbox',
    },
    groupsVisible: {
      name: 'Groups',
      type: 'checkbox',
    },
    peopleVisible: {
      name: 'People',
      type: 'checkbox',
    },
    platformVisible: {
      name: 'Platform',
      type: 'checkbox',
    },
    pmVisible: {
      name: 'Product Management',
      type: 'checkbox',
    },
    publishingVisible: {
      name: 'Publishing',
      type: 'checkbox',
    },
    registrationsVisible: {
      name: 'Registrations',
      type: 'checkbox',
    },
    calendarVisible: {
      name: 'Calendar',
      type: 'checkbox',
    },
    servicesVisible: {
      name: 'Services',
      type: 'checkbox',
    },
  }

  static defaultSettings = {
    accountsVisible: true,
    apiVisible: true,
    check_insVisible: true,
    givingVisible: true,
    groupsVisible: true,
    peopleVisible: true,
    platformVisible: true,
    pmVisible: true,
    publishingVisible: true,
    registrationsVisible: true,
    calendarVisible: true,
    servicesVisible: true,
  }

  static defaultPrplatform = {
    data: {
      accounts: { onCall: '' },
      api: { onCall: '' },
      check_ins: { onCall: '' },
      giving: { onCall: '' },
      groups: { onCall: '' },
      people: { onCall: '' },
      platform: { onCall: '' },
      pm: { onCall: '' },
      publishing: { onCall: '' },
      resgistrations: { onCall: '' },
      calendar: { onCall: '' },
      services: { onCall: '' },
    },
  }

  static dataSource = 'stats'

  render() {
    return (
      <div className="oncall">
        <div className="oncall-widget">
          <div className="card-header">
            <Icons.Oncall />
            <span>On Call</span>
          </div>
          <div className="card-body">
            <ul className="oncall-list" style={{ listStyle: 'none' }}>
              {APPS.map(
                app =>
                  this.props[`${app}Visible`] && (
                    <li className="oncall-app__list" key={app}>
                      <AppIcon
                        name={app}
                        className="oncall-app__icon"
                        fill={Oncall.config[app].appColor}
                      />
                      {this.props.data[app] && this.props.data[app].onCall}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
