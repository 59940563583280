import React from 'react'
import AnimatedValue from './AnimatedValue'
import capitalize from '../utils/capitalize'

import * as Icons from 'glob:./icons/*.js'

export default class MapHeader extends React.Component {
  render() {
    const {
      users,
      cities,
      countries,
      usersVisible,
      citiesVisible,
      countriesVisible,
      app,
    } = this.props
    return (
      <div className="card-header map-header">
        <h2>
          <Icons.Map />
          {capitalize(app).replace('_', '-')}
        </h2>
        <div className="map-stats">
          {usersVisible && (
            <span>
              Users: <AnimatedValue value={users} />
            </span>
          )}
          {citiesVisible && (
            <span>
              Cities: <AnimatedValue value={cities} />{' '}
            </span>
          )}
          {countriesVisible && (
            <span>
              Countries: <AnimatedValue value={countries} />{' '}
            </span>
          )}
        </div>
      </div>
    )
  }
}
