import React from 'react'

const AppleStore = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 96 960 960" fill={fill}>
    <path d="M840 537v339q0 24-18 42t-42 18H179q-24 0-42-18t-18-42V537q-28-24-37-59t2-70l43-135q8-27 28-42t46-15h553q28 0 49 15.5t29 41.5l44 135q11 35 1.5 70T840 537Zm-270-31q29 0 49-19t16-46l-25-165H510v165q0 26 17 45.5t43 19.5Zm-187 0q28 0 47.5-19t19.5-46V276H350l-25 165q-4 26 14 45.5t44 19.5Zm-182 0q24 0 41.5-16.5T263 449l26-173H189l-46 146q-10 31 8 57.5t50 26.5Zm557 0q32 0 50.5-26t8.5-58l-46-146H671l26 173q3 24 20.5 40.5T758 506ZM179 876h601V565q1 1-6.5 1H758q-25 0-47.5-10.5T666 523q-16 20-40 31.5T573 566q-30 0-51.5-8.5T480 529q-15 18-38 27.5t-52 9.5q-31 0-55-11t-41-32q-24 21-47 32t-46 11h-13.5q-6.5 0-8.5-1v311Zm601 0H179h601Z" />
  </svg>
)

AppleStore.defaultProps = {
  width: '24px',
  height: '24px',
  fill: '#fff',
}

export default AppleStore