import React from 'react'

const Person = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 468.54246 386.7813"
    fill={fill}
  >
    <g transform="translate(-80.246356,-208.85725)">
      <path d="M 314.54492,397.5625 C 194.14128,397.60956 91.468122,474.93561 80.330078,579.11719 a 14.934994,14.934994 0 0 0 14.84961,16.52148 l 438.675782,0 a 14.934994,14.934994 0 0 0 14.84961,-16.52148 C 537.56953,474.95874 434.93738,397.63996 314.56055,397.5625 a 14.934994,14.934994 0 0 0 -0.0156,0 z m -0.004,29.86719 0.0156,0 c 100.6596,0.071 183.76056,60.06979 201.6543,138.34179 l -403.38672,0 C 130.72169,487.48075 213.85686,427.47531 314.5409,427.42969 Z m 0.01,-218.57227 c -60.14322,0 -109.22071,49.07553 -109.2207,109.21875 0,60.14322 49.07748,109.21875 109.2207,109.21875 60.14322,0 109.21875,-49.07553 109.21875,-109.21875 10e-6,-60.14322 -49.07552,-109.21875 -109.21875,-109.21875 z m 0,29.86719 c 44.0019,0 79.35157,35.34966 79.35156,79.35156 0,44.0019 -35.34966,79.35156 -79.35156,79.35156 -44.0019,0 -79.35351,-35.34966 -79.35351,-79.35156 -1e-5,-44.0019 35.35161,-79.35156 79.35351,-79.35156 z" />
    </g>
  </svg>
)

Person.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#c3c3c3',
}

export default Person
