import React from 'react'

const Download = ({ width, height, fill }) => (
    <svg width={width} height={height} viewBox="0 96 960 960" fill={fill}>
        <path d="M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 24-18 42t-42 18H220Zm260-153L287 550l43-43 120 120V256h60v371l120-120 43 43-193 193Z" />
    </svg>
)

Download.defaultProps = {
    width: '30px',
    height: '30px',
    fill: '#fff',
}

export default Download
