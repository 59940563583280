import { combineReducers } from 'redux'
import dashboard from './dashboard'
import data from './data'
import layout from './layout'
import settings from './settings'
import status from './status'
import widgets from './widgets'
import queryString from 'query-string'
import moment from 'moment'

export default function(state, action) {
  switch (action.type) {
    case 'BOOT':
      const params = queryString.parse(location.search)
      const isClient = Object.keys(params).indexOf('client') !== -1

      if (isClient) {
        const now = moment()
        const bootTime = moment.unix(action.payload.data.time)
        const recentBoot = bootTime.add(60, 'seconds') > now
        const reloadedAt = localStorage.getItem('reloadedAt')
        const notRecentlyReloaded =
          !reloadedAt || moment.unix(reloadedAt).add(60, 'seconds') < now

        if (recentBoot && notRecentlyReloaded) {
          localStorage.setItem('reloadedAt', action.payload.data.time)
          location.reload()
        }
      }

      return state
    default:
      return combineReducers({
        dashboard,
        data,
        layout,
        settings,
        status,
        widgets,
      })(state, action)
  }
}
