import React from 'react'
import Widget from '../Widget'
import { appDisplayNames } from '../../utils/mobile_app_names'
import { appColors } from '../../utils/mobile_app_colors'
import AppIcon from '../AppIcon'
import capitalize from '../../utils/capitalize'
import * as Icons from 'glob:../icons/*.js'

import { MobileStatsInfo } from './MobileStats/Info'
import { MobileStatsLinks } from './MobileStats/Links'

import '../../styles/mobile-stats.scss'

class MobileStats extends Widget {
  static dataSource = 'mobileOverview'

  static settings = {
    app: {
      name: 'App',
      type: 'select',
      options: Object.entries(appDisplayNames).map(([key, name]) =>
        [name, key]
      )
    },
    appVersionVisible: {
      name: 'Version',
      type: 'checkbox'
    },
    appRatingVisible: {
      name: 'Rating',
      type: 'checkbox'
    },
    appDownloadsVisible: {
      name: 'Downloads',
      type: 'checkbox'
    },
    appCrashVisible: {
      name: 'Crash %',
      type: 'checkbox'
    },
  }

  static defaultSettings = {
    app: 'services',
    appVersionVisible: true,
    appRatingVisible: true,
    appDownloadsVisible: true,
    appCrashVisible: true,
  }

  render() {
    const { app } = this.props
    const { appColor } = appColors[app]

    const getAppData = (appName) => {
      return this.props.data[appName] || data
    }

    const data = {
      android: {
        version: 'Not Set',
        rating: 0,
        reviewCount: 0,
        crashFreeUsersRate: 0,
        launch: 0,
        adoptionPercent: 0,
        platform_newUsers: 0,
      },
      ios: {
        version: 'Not Set',
        rating: 0,
        reviewCount: 0,
        crashFreeUsersRate: 0,
        launch: 0,
        adoptionPercent: 0,
        platform_newUsers: 0,
      }
    }

    return (
      <div className="mobile-stats">
        <div className="card-header mobile-stats--app" style={{ backgroundColor: appColor }}>
          <h1 className="stats-app__name">
            <AppIcon name={app ? app : 'services'} className="stats-app__icon" />
            {capitalize(app ? app : 'services').replace(/_/g, '-')}
          </h1>
          <MobileStatsLinks app={app} category="general" />
        </div>
        <div className="mobile-stats--wrapper">
          <div className="mobile-stats--ios">
            <div>
              <div className="mobile-stats--category">
                <Icons.Apple height={50} width={50} fill="currentColor" />
              </div>
              <MobileStatsLinks app={app} category="ios" />
            </div>
            <MobileStatsInfo props={this.props} data={getAppData(app).ios} />
          </div>
          <div className="mobile-stats--android">
            <div>
              <div className="mobile-stats--category">
                <Icons.Android height={50} width={50} fill="currentColor" />
                <MobileStatsLinks app={app} category="android" />
              </div>
            </div>
            <MobileStatsInfo props={this.props} data={getAppData(app).android} />
          </div>
        </div>
      </div>
    )
  }
}

export default MobileStats
