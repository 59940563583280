import React from 'react'

const Map = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M57.51,99.3A6.37,6.37,0,0,1,53,97.42L32.74,77.19a35,35,0,1,1,49.54,0L62,97.42A6.37,6.37,0,0,1,57.51,99.3Zm1.12-7.54h0ZM57.51,25.39A27,27,0,0,0,38.39,71.53L57.51,90.65,76.62,71.53A27,27,0,0,0,57.51,25.39Z" />
    <circle cx="57.51" cy="52.42" r="10.22" />
  </svg>
)

Map.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Map
