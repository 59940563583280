import classNames from 'classnames'
import * as Icons from 'glob:./icons/*.js'
import * as widgetTypes from 'glob:./widgets/*.js'
import React, { Component, createElement } from 'react'
import { Motion, spring } from 'react-motion'
import { connect } from 'react-redux'
import { toggleSettingsActive } from '../actions/status'
import { removeWidget } from '../actions/widgets'
import capitalize from '../utils/capitalize'
import CardSettings from './CardSettings'

import '../styles/card.scss'

function getCardData(state, { type, settings }) {
  const {
    status: { settingsActive },
  } = state
  const widget = widgetTypes[capitalize(type)]
  const cardData = !!widget ? widget.getCardData(state, settings) : {}

  return { data: cardData, settingsActive }
}

class Card extends Component {
  _toggleSettings = () => {
    const { dispatch, id } = this.props
    dispatch(toggleSettingsActive(id))
  }

  render() {
    const { id, type, settings, data, dispatch } = this.props
    const settingsActive = this.props.settingsActive.indexOf(id) !== -1
    const { size, app } = settings
    const classes = classNames(
      'card',
      { [`card--${type}`]: !!type },
      { [`card--${app}`]: !!app },
      { [`card--${size}`]: !!size },
      { editing: settingsActive }
    )
    const widgetType = widgetTypes[capitalize(type)]

    if (!widgetType) return null

    const component = createElement(widgetTypes[capitalize(type)], {
      ...settings,
      data,
      dispatch,
      id,
    })

    const hasSettings = !!component.type.settings

    return (
      <Motion
        style={{
          y: spring(settingsActive ? 180 : 0, { stiffness: 238, damping: 34 }),
        }}
      >
        {({ y }) => (
          <div className={classes} style={{ transform: `rotateY(${y}deg)` }}>
            <div className="card-front">{component}</div>

            {hasSettings && (
              <div className="card-back">
                <CardSettings
                  id={id}
                  type={type}
                  component={component}
                  toggleSettings={this._toggleSettings}
                />
              </div>
            )}

            <nav className="card-nav">
              <button
                className="card-nav-btn"
                onClick={() => this.props.dispatch(removeWidget(id))}
              >
                <Icons.Close fill="#fff" />
              </button>
              {hasSettings && (
                <div className="card-nav-btn" onClick={this._toggleSettings}>
                  <Icons.Cog fill="#fff" />
                </div>
              )}
            </nav>
          </div>
        )}
      </Motion>
    )
  }
}

export default connect(getCardData)(Card)
