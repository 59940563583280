import React from 'react'

const Marker = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M58.008,99.802c-1.714,0-3.325-0.667-4.537-1.88L33.238,77.69c-6.617-6.616-10.262-15.414-10.262-24.771
			s3.645-18.154,10.262-24.771c6.616-6.617,15.413-10.262,24.771-10.262s18.155,3.645,24.771,10.262
			c13.659,13.658,13.659,35.883,0,49.542L62.546,97.922C61.334,99.135,59.723,99.802,58.008,99.802z M59.129,92.267h0.01H59.129z
			 M58.008,25.886c-7.221,0-14.009,2.812-19.114,7.918s-7.918,11.894-7.918,19.114s2.812,14.009,7.918,19.115l19.115,19.113
			l19.114-19.113c10.539-10.54,10.539-27.689,0-38.229C72.017,28.699,65.229,25.886,58.008,25.886z"
    />
    <circle cx="58.008" cy="52.919" r="10.222" />
  </svg>
)

Marker.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#c3c3c3',
}

export default Marker
