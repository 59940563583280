import React from 'react'
import Widget from '../Widget'
import moment from 'moment'

import * as Icons from 'glob:../icons/*.js'

import '../../styles/clock.scss'

class Clock extends Widget {
  state = this._time
  static dataSource = 'clock'
  _interval = null

  componentWillMount() {
    this._interval = setInterval(() => this.setState(this._time), 60)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  get _time() {
    const now = moment()
    return {
      time: now.format('h:mm'),
      period: now.format('a'),
      date: now.format('MMMM Do YYYY'),
      seconds: now.format('ss'),
    }
  }

  render() {
    const { time, period, date, seconds } = this.state
    return (
      <time className={`clock clock--${this.props.size}`}>
        <div className="no-header-icon">
          <Icons.Clock />
        </div>
        <div className="card-body">
          <div className="clock-time">{time}</div>
          <div className="clock-date">{date}</div>
        </div>
        <div className="clock-period">{period}</div>
        <div
          className="clock-seconds"
          style={{ width: `${(seconds / 60) * 100}%` }}
        />
      </time>
    )
  }
}

export default Clock
