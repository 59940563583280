import React from 'react'

const Clock = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M57.5,97.5a40,40,0,1,1,40-40A40,40,0,0,1,57.5,97.5Zm0-72a32,32,0,1,0,32,32A32,32,0,0,0,57.5,25.5Z" />
    <polygon points="65.75 72.68 53.5 63.12 53.5 39.33 61.5 39.33 61.5 59.21 70.67 66.37 65.75 72.68" />
  </svg>
)

Clock.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Clock
