import React, { Component } from 'react'
import classNames from 'classnames'
import AnimatedValue from '../../AnimatedValue'

class Server extends Component {
  static defaultProps = {
    error_rate: '0.0%',
    throughput: 0,
    current_failed: 0,
    working: 0,
    workers: 0,
    pending: 0,
  }

  render() {
    const {
      bugsnagVisible,
      errorRateVisible,
      jobsVisible,
      onCallVisible,
      rpmVisible,
      workersVisible,
    } = this.props
    const failedJobClasses = classNames(
      { 'danger-pill': this.props.current_failed !== 0 },
      { 'no-pill': this.props.current_failed === 0 }
    )
    return (
      <div>
        <ul>
          {rpmVisible && (
            <li>
              <span>RPM</span>
              <div>{this.props.throughput}</div>
            </li>
          )}
          {errorRateVisible && (
            <li>
              <span>Error Rate</span>
              <div>{this.props.error_rate}</div>
            </li>
          )}
          {jobsVisible && (
            <li>
              <span>Jobs</span>
              <span className="jobs-container">
                <span className={failedJobClasses}>
                  <AnimatedValue value={this.props.current_failed} />
                </span>
                /
                <span className="passed">
                  <AnimatedValue value={this.props.pending} />
                </span>
              </span>
            </li>
          )}
          {onCallVisible && (
            <li>
              <span>On Call</span>
              <div>{this.props.onCall}</div>
            </li>
          )}
          {bugsnagVisible && (
            <li>
              <span>Bugsnag</span>
              <div>{this.props.bugsnag}</div>
            </li>
          )}
          {workersVisible && (
            <li>
              <span>Workers</span>
              <div>
                <span>
                  <AnimatedValue value={this.props.working} />
                </span>
                /
                <span>
                  <AnimatedValue value={this.props.workers} />
                </span>
              </div>
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default Server
