import { UPDATE_LAYOUT } from '../actions/layout'
import { UPDATED_DASHBOARD } from '../actions/dashboard'

export default (state = [], action) => {
  switch (action.type) {
    case UPDATED_DASHBOARD:
      const { data } = action.payload
      return data.id === action.dashboard ? data.config.layout : state
    case UPDATE_LAYOUT:
      return action.payload
    default:
      return state
  }
}
