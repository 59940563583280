import React from 'react'

const Support = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 50.04 67.21" fill={fill}>
    <path
      d="M57,27.36a25,25,0,0,0-25,25v3h0V70.14a6.55,6.55,0,0,0,5.16,6.39,13.66,13.66,0,0,0,9.53,12.26,6.2,6.2,0,0,0,6.17,5.78h5.56a6.2,6.2,0,0,0,6.19-6.19V85a6.2,6.2,0,0,0-6.19-6.19H52.78a6.2,6.2,0,0,0-6,4.64,8.66,8.66,0,0,1-4.7-6.8h1.56a3.53,3.53,0,0,0,3.52-3.52V58.91a3.53,3.53,0,0,0-3.52-3.52H37v-3a20,20,0,0,1,40,0v3H70.22a3.53,3.53,0,0,0-3.52,3.52V73.15a3.53,3.53,0,0,0,3.52,3.52h5.19A6.55,6.55,0,0,0,82,70.14V52.36A25,25,0,0,0,57,27.36ZM51.59,85a1.2,1.2,0,0,1,1.19-1.19h5.56A1.2,1.2,0,0,1,59.54,85v3.34a1.2,1.2,0,0,1-1.19,1.19H52.78a1.2,1.2,0,0,1-1.19-1.19ZM42.17,60.38V71.68H38.45a1.54,1.54,0,0,1-1.54-1.54v-7.5h0V60.38ZM77,70.14a1.54,1.54,0,0,1-1.54,1.54H71.7V60.38H77v9.75Z"
      transform="translate(-31.92 -27.36)"
    />
  </svg>
)

Support.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Support
