import React from 'react'

const Moon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M67.67,89.92a31.56,31.56,0,0,1-23.21-10.3A32.56,32.56,0,0,1,36,55.21c1.09-16.14,13.84-29.06,29.66-30.06a31.41,31.41,0,0,1,9.17.76A5.55,5.55,0,0,1,79,30.43a5.76,5.76,0,0,1-2.51,5.8,25.51,25.51,0,0,0,0,42.54A5.75,5.75,0,0,1,79,84.56a5.59,5.59,0,0,1-4.23,4.54A31.23,31.23,0,0,1,67.67,89.92Zm0-57.63q-0.79,0-1.57,0A25,25,0,0,0,43,55.71a25.35,25.35,0,0,0,6.58,19,24.22,24.22,0,0,0,18.05,8q1,0,2-.08a32.86,32.86,0,0,1,0-50.26Q68.68,32.29,67.66,32.29Z" />
  </svg>
)

Moon.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#fff',
}

export default Moon
