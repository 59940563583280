import { UPDATE_PERSONAPPS } from '../actions/personapps'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PERSONAPPS:
      return action.payload
    default:
      return state
  }
}
