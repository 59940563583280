import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import AnimatedValue from '../../AnimatedValue'

import '../../../styles/meter.scss'

class Meter extends Component {
  static defaultProps = {
    value: 0,
  }

  componentDidMount() {
    const canvas = findDOMNode(this.refs.canvas)
    this.guage = new Donut(canvas).setOptions(this.guageOptions)
    this.guage.maxValue = 360
    this.guage.animationSpeed = 32
    this.guage.set(1)
  }

  componentDidUpdate(prevProps) {
    if (!isNaN(prevProps.value)) {
      this.guage.set(prevProps.value)
    }
    if (
      prevProps.fillColor !== this.props.fillColor ||
      prevProps.appColor !== this.props.appColor
    ) {
      this.guage.setOptions({
        colorStart: this.props.fillColor,
        colorStop: this.props.fillColor,
        strokeColor: this.props.appColor,
        shadowColor: this.props.appColor,
      })
    }
  }

  get guageOptions() {
    const { appColor, fillColor } = this.props
    return {
      lines: 12,
      angle: 0.5,
      lineWidth: 0.15,
      limitMax: 'false',
      strokeColor: appColor,
      colorStart: fillColor, // Colors
      colorStop: fillColor, // just experiment with them
      shadowColor: appColor,
    }
  }

  render() {
    return (
      <div className="product_health_container">
        <div className="product_health">
          <canvas ref="canvas" className="gauge" />
          <div className="product_health_digits">
            <span>
              <AnimatedValue value={this.props.value} />
            </span>
            <span className="small">ms</span>
          </div>
        </div>
      </div>
    )
  }
}

export default Meter
