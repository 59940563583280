import { UPDATE_ACTIVE_USERS } from '../actions/active_users'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_USERS:
      const { app, data } = action.payload
      return { ...state, [app]: { ...state[app], ...data } }
    default:
      return state
  }
}
