import React from 'react'

const RED = '#f14e2d'
const BLUE = '#339cbe'
const GREEN = '#8ebd3e'
const ORANGE = '#ffb12a'

export default class MapBody extends React.Component {
  constructor(props) {
    super(props)
    this.existingMarkers = {}
    this.googlePoints = new google.maps.MVCArray()
    this.handleMapChange = this.handleMapChange.bind(this)
  }

  componentDidMount() {
    const { zoom, lat, lng } = this.props
    const options = {
      zoom: parseInt(zoom),
      minZoom: 2,
      center: new google.maps.LatLng(lat, lng),
      disableDefaultUI: true,
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
      styles: this.style,
    }

    this.map = new google.maps.Map(this.refs.map, options)
    setTimeout(() => {
      google.maps.event.trigger(this.map, 'resize')
      google.maps.event.addListener(
        this.map,
        'zoom_changed',
        this.handleMapChange
      )
      google.maps.event.addListener(this.map, 'dragend', this.handleMapChange)
    })
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.defaultTheme !== nextProps.defaultTheme) {
      return true
    }

    if (this.props.zoom !== nextProps.zoom) {
      return true
    }

    if (this.props.lat !== nextProps.lat) {
      return true
    }

    if (this.props.lng !== nextProps.lng) {
      return true
    }

    for (var key in nextProps.markers) {
      const [_lat, _lng, _city, _region, _country, count] = nextProps.markers[
        key
      ]
      const shouldUpdate =
        !(key in this.existingMarkers) || this.existingMarkers[key][5] !== count
      if (shouldUpdate) {
        return true
      }
    }
    return false
  }

  componentDidUpdate() {
    const { markers, zoom, lat, lng } = this.props

    // Clear Stale Markers
    for (var key in this.existingMarkers) {
      if (!(key in markers)) {
        delete this.existingMarkers[key]
      }
    }

    this.googlePoints.clear()

    for (var key in markers) {
      const [lat, lng, city, _region, country, count] = markers[key]
      this.googlePoints.push({
        location: new google.maps.LatLng(lat, lng),
        weight: parseInt(count),
      })
      this.existingMarkers[key] = markers[key]
    }

    if (!this.heatMap) {
      this.heatMap = new google.maps.visualization.HeatmapLayer({
        data: this.googlePoints,
        map: this.map,
        gradient: this.gradient,
      })
    }
    this.map.setOptions({
      styles: this.style,
      zoom: parseInt(zoom),
      lat: lat,
      lng: lng,
    })
  }

  handleMapChange() {
    this.props.onMapChange(this.map.getZoom(), this.map.getCenter())
  }

  get gradient() {
    return [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 255, 255, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(0, 127, 255, 1)',
      'rgba(0, 63, 255, 1)',
      'rgba(0, 0, 255, 1)',
      'rgba(0, 0, 223, 1)',
      'rgba(0, 0, 191, 1)',
      'rgba(0, 0, 159, 1)',
      'rgba(0, 0, 127, 1)',
      'rgba(63, 0, 91, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(255, 0, 0, 1)',
    ]
  }

  get style() {
    return [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
          {
            color: this.props.defaultTheme ? '#ffffff' : '#0c0d10',
          },
        ],
      },
      {
        featureType: 'road.highway',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        stylers: [
          {
            color: this.props.defaultTheme ? '#d3d3d3' : '#26282d',
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            color: this.props.defaultTheme ? '#d3d3d3' : '#26282d',
          },
        ],
      },
      {
        featureType: 'administrative',
        stylers: [
          {
            color: this.props.defaultTheme ? '#d3d3d3' : '#26282d',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: this.props.defaultTheme ? '#d3d3d3' : '#26282d',
          },
        ],
      },
      {
        featureType: 'administrative.country',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: this.props.defaultTheme ? '#d3d3d3' : '#26282d',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
    ]
  }

  render() {
    return <div ref="map" style={{ width: '100%', height: '100%' }} />
  }
}
