export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_SETTINGS_ACTIVE = 'TOGGLE_SETTINGS_ACTIVE'

export const toggleSidebar = () => {
  return { type: TOGGLE_SIDEBAR }
}

export const toggleSettingsActive = id => {
  return {
    type: TOGGLE_SETTINGS_ACTIVE,
    payload: {
      id: id,
    },
  }
}
