import React from 'react'

const Alerts = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M98.8,68.93L92.37,52.77a4,4,0,0,0-5.2-2.24A7.9,7.9,0,0,1,76.92,46.1l-3.57-9A29.26,29.26,0,0,0,46.52,18.8a11,11,0,0,0-20.31,8.08,29.26,29.26,0,0,0-6.92,31.76l3.57,9a7.9,7.9,0,0,1-4.42,10.26,4,4,0,0,0-2.24,5.19l6.43,16.17a4,4,0,0,0,5.2,2.24l19.65-7.82A16.39,16.39,0,0,0,76.91,81.94l19.65-7.82A4,4,0,0,0,98.8,68.93ZM63.73,91.66a8.4,8.4,0,0,1-7.8-.84L68.82,85.7A8.4,8.4,0,0,1,63.73,91.66Zm-35.15.89-3.66-9.2a15.92,15.92,0,0,0,5.37-18.7l-3.57-9a21.19,21.19,0,0,1,6.62-24.42,4,4,0,0,0,1.26-4.63l-0.49-1.22a3,3,0,1,1,5.64-2.24l0.49,1.22a4,4,0,0,0,4.09,2.5A21.2,21.2,0,0,1,65.92,40.1l3.57,9A15.92,15.92,0,0,0,86.23,59l3.66,9.2Z" />
  </svg>
)

Alerts.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Alerts
