import React from 'react'

const Calendar = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 82.7 86" fill={fill}>
    <path
      d="M78.9,6.7H64V3.5C64,1.6,62.4,0,60.5,0S57,1.6,57,3.5v3.2H24.5V3.5C24.5,1.6,22.9,0,21,0s-3.5,1.6-3.5,3.5v3.2H3.8
      C1.7,6.7,0,8.3,0,10.4v71.9C0,84.3,1.7,86,3.8,86h75.1c2.1,0,3.8-1.7,3.8-3.7V10.4C82.7,8.3,81,6.7,78.9,6.7z M17.5,14.1v2.3
      c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5v-2.3h14.3h7.6H57v2.3c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5v-2.3h11.1v15.6H7.6V14.1H17.5
      z M75.1,78.5H46.4h-7.6H7.6V36.7h67.5v5.5V78.5z"
    />
    <polygon points="38.9,59.3 32.1,52.5 27.1,57.5 38.9,69.2 59.7,48.4 54.7,43.5     " />
  </svg>
)

Calendar.defaultProps = {
  width: '30px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Calendar
