import {
  ADD_WIDGET,
  REMOVE_WIDGET,
  REMOVE_WIDGETS,
  UPDATE_WIDGET,
  UPDATE_SETTING,
} from '../actions/widgets'
import { UPDATED_DASHBOARD } from '../actions/dashboard'

export default (state = [], action) => {
  switch (action.type) {
    case UPDATED_DASHBOARD:
      const { data } = action.payload
      return data.id === action.dashboard ? data.config.widgets : state
    case ADD_WIDGET:
      return [...state, action.payload]
    case REMOVE_WIDGET:
      return state.filter(widget => widget.id !== action.payload)
    case REMOVE_WIDGETS:
      return []
    case UPDATE_WIDGET:
      return state.map(widget => {
        if (widget.id !== action.payload.id) {
          return widget
        }
        return {
          ...widget,
          ...action.payload.properties,
          settings: widget.settings,
        }
      })
    case UPDATE_SETTING:
      let { id, key, value } = action.payload

      return state.map(widget => {
        if (widget.id !== id) {
          return widget
        }
        return {
          ...widget,
          settings: { ...widget.settings, [key]: value },
        }
      })
    default:
      return state
  }
}
