import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import Chart from 'chart.js'
import AnimatedValue from '../../AnimatedValue'

import '../../../styles/graph.scss'

class Graph extends Component {
  static defaultProps = {
    points: [0, 0, 0, 0, 0, 0, 0],
  }

  componentDidMount() {
    const canvas = findDOMNode(this.refs.canvas)
    this.chart = new Chart(canvas.getContext('2d'))
    this.renderGraph()
  }

  componentDidUpdate() {
    this.renderGraph()
  }

  renderGraph() {
    const days = ['', '', '', '', '', '', '']
    const max = Math.max.apply(Math, this.props.points)
    const scaleStep = Math.ceil(max / 20)
    const primaryUnit = Math.pow(10, scaleStep.toString().length - 1)
    const scaleStepRounded = Math.ceil(scaleStep / primaryUnit) * primaryUnit
    const stepCount = Math.ceil(max / scaleStepRounded) + 1
    const fillColor = this.props.fillColor || 'rgba(130,192,105,1)'
    const strokeColor = this.strokeColor || 'rgba(160,207,142,0)'
    const pointColor = this.pointColor || 'rgba(151,187,205,1)'

    const lineChartData = {
      labels: days,
      datasets: [
        {
          fillColor: fillColor,
          strokeColor: strokeColor,
          pointColor: pointColor,
          datasetStrokeWidth: '6',
          data: this.props.points,
        },
      ],
    }

    const options = {
      animation: false,
      scaleOverride: true,
      scaleStartValue: scaleStepRounded,
      scaleSteps: stepCount - 1,
      scaleStepWidth: scaleStepRounded,
      scaleShowLabels: false,
      pointDot: false,
      scaleLineColor: 'rgba(0,0,0,0)',
      scaleShowVerticalLines: false,
      scaleShowHorizontalLines: false,
    }

    this.chart.Line(lineChartData, options)
  }

  render() {
    const latest = this.props.points[this.props.points.length - 1] || 0

    return (
      <div className="graph_container dark_column">
        <div className="graph">
          <canvas ref="canvas" />
        </div>
        <div className="week-scale">
          <img src="/assets/week-line.svg" />
        </div>
        <div className="avg_stat">
          <AnimatedValue value={latest} />
        </div>
        <div className="title">
          <span>{this.props.title}</span>
        </div>
      </div>
    )
  }
}

export default Graph
