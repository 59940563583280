import React from 'react'

import '../styles/tooltip.scss'

export function Tooltip({ tooltip, children, className }) {
    return (
        <div className={`tooltip ${className ?? ""}`}>
            {children}
            <div className="tooltip--text">{tooltip}</div>
        </div>
    )
}
