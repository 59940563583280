import React from 'react'

const AddBtn = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <polygon points="102.36 61.64 102.35 53.36 61.64 53.36 61.64 12.65 53.36 12.64 53.36 53.36 12.64 53.36 12.65 61.64 53.36 61.64 53.36 102.35 61.64 102.36 61.64 61.64 102.36 61.64" />
  </svg>
)

AddBtn.defaultProps = {
  width: '20px',
  height: '20px',
  fill: '#fff',
}

export default AddBtn
