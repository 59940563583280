export function formatPercent(percent) {
    return `${(percent * 100).toFixed()}%`
}

export function formatCount(count, decimals) {
    if (count >= 1000000) {
        return `${(count / 1000000).toFixed(decimals).toLocaleString('en-US')}m`
    } else if (count >= 1000) {
        return `${(count / 1000).toFixed(decimals).toLocaleString('en-US')}k`
    }
    return count.toLocaleString('en-US')
}

export function formatSeconds(seconds) {
    if (seconds < 60) {
        return `${seconds}s`
    } else if (seconds < 60 * 60) {
        return `${(seconds / 60).toFixed()}m ${seconds % 60}s`
    } else {
        return `${(seconds / (60 * 60)).toFixed}h ${Math.ceil(seconds / 60) % 60}m`
    }
}