import React from 'react'

const Pico = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <ellipse cx="40.62" cy="65.96" rx="4.73" ry="8.23" />
    <ellipse cx="73.22" cy="65.96" rx="4.73" ry="8.23" />
    <path d="M90,58V55.77c0-4-3.34-7.49-7.38-8.19l-2.69-.42C76.1,46.5,72.4,46,68.51,45.68,68,42.8,64,40.47,60,39.86v-4c1-.92,3.19-3,3.19-5.31a6,6,0,0,0-6-5.84c-3.25,0-6.29,2.62-6.29,5.84C50.89,32.9,53,35,54,35.86v4c-4,.61-7.86,2.94-8.37,5.82-4,.33-7.79.83-11.72,1.51l-2.7.41A8.63,8.63,0,0,0,24,55.77V58c-2,1.18-5,4.2-5,8v0.18c0,3.82,3,7.31,5,8.72v1.58c0,4.26,3.55,7.73,7.71,8.24l8.8,1.07a136.43,136.43,0,0,0,16.61,1,135.35,135.35,0,0,0,17.23-1.1l7.89-1c4.23-.55,7.76-4.09,7.76-8.24V75c2-1.41,5-4.9,5-8.71V66.06C95,62.24,92,59.23,90,58ZM83,76.57a2.45,2.45,0,0,1-2.09,2.54l-7.39,1a128.14,128.14,0,0,1-32.12.08L33,79.09a2.35,2.35,0,0,1-2-2.55V55.77a2.37,2.37,0,0,1,1.92-2.53l2.21-.41a127.49,127.49,0,0,1,43.7,0L81,53.25a2.42,2.42,0,0,1,2,2.53V76.57Z" />
    <path d="M63.76,72.55a1.82,1.82,0,0,0-1.4-.1,16.37,16.37,0,0,1-10.29,0,1.83,1.83,0,1,0-1.16,3.48,20.06,20.06,0,0,0,12.61,0A1.83,1.83,0,0,0,63.76,72.55Z" />
  </svg>
)

Pico.defaultProps = {
  width: '80px',
  height: '80px',
  fill: '#c3c3c3',
}

export default Pico
