import React from 'react'
import Widget from '../Widget'
import moment from 'moment'
import classNames from 'classnames'
import '../../styles/support.scss'
import * as Icons from 'glob:../icons/*.js'

export default class Support extends Widget {
  static defaultSettings = {
    size: 'normal',
    team: 'Green Team',
    first_reply_alert: 60,
    advanced: true,
    ticket_alert: 100,
    advanced_ticket_alert: 100,
  }

  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [['Small', 'small'], ['Normal', 'normal']],
    },
    team: {
      name: 'Team',
      type: 'select',
      options: [['Green', 'Green Team'], ['Blue', 'Blue Team']],
    },
    advanced: {
      name: 'Advanced Tickets',
      type: 'checkbox',
      settings: (() => {
        let childSettings = {}
        childSettings['advanced_ticket_alert'] = {
          name: 'Alert advanced tickets over',
          type: 'number',
          step: 1,
          max: 100,
          min: 1,
        }
        return childSettings
      })(),
    },
    first_reply_alert: {
      name: 'Alert first reply over (minutes)',
      type: 'number',
      step: 5,
      max: 120,
      min: 0,
    },
    ticket_alert: {
      name: 'Alert tickets over',
      type: 'number',
      step: 1,
      max: 100,
      min: 1,
    },
  }

  static getCardData(state, settings) {
    return state.data['support'][settings.team]
  }

  get data() {
    return { ...this.constructor.defaultProps.data, ...this.props.data }
  }

  get firstReply() {
    return Math.floor(
      moment.duration(moment().diff(this.data.oldest)).asMinutes()
    )
  }

  render() {
    const {
      advanced,
      ticket_alert,
      advanced_ticket_alert,
      first_reply_alert,
    } = this.props
    const {
      advanced_tickets,
      color,
      name,
      tickets,
      advanced_id,
      ticket_id,
      timer_id,
    } = this.data
    const classes = classNames('card-right', 'support-card-container', {
      danger:
        parseInt(tickets) > ticket_alert ||
        parseInt(advanced_tickets) > advanced_ticket_alert ||
        parseInt(this.firstReply) > first_reply_alert,
    })
    return (
      <div className="card-front support">
        <div className="no-header-icon">
          <Icons.Support />
        </div>
        <div className="card-left">
          <div className="support-team">
            <h1 className="support-team__name" style={{ color: color }}>
              {name}
            </h1>
          </div>
          {advanced && (
            <div className="support-meta">
              <a
                href={`https://pco.zendesk.com/agent/filters/${advanced_id}`}
                target="_blank"
                className="support-team__advanced"
              >
                <span>{advanced_tickets}</span>
                Adv Tickets
              </a>
            </div>
          )}
        </div>
        <div className={classes} style={{ backgroundColor: color }}>
          <a
            href={`https://pco.zendesk.com/agent/filters/${ticket_id}`}
            target="_blank"
            className="support-team__tickets"
          >
            {tickets}
          </a>
          <a
            href={`https://pco.zendesk.com/agent/filters/${timer_id}`}
            target="_blank"
            className="support-team__oldest"
          >
            First Reply: {this.firstReply} min
          </a>
        </div>
      </div>
    )
  }
}
