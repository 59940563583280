import { UPDATE_TASKS } from '../actions/tasks'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TASKS:
      return action.payload.data
    default:
      return state
  }
}
