export default [
    'calendar',
    'check_ins',
    'church_center_app',
    'headcounts',
    'music_stand',
    'people',
    'services',
  ]

  export const appDisplayNames = {
    calendar: 'Calendar',
    check_ins:  'Check-Ins',
    church_center_app: 'Church Center App',
    headcounts: 'Headcounts',
    music_stand: 'Music Stand',
    people: 'People',
    services: 'Services',
  }
  