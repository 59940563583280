import React from 'react'

const Sun = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path d="M37.56,57.5A19.94,19.94,0,1,0,57.5,37.16,20.14,20.14,0,0,0,37.56,57.5ZM57.51,28.94a3.15,3.15,0,0,0,3.11-3.18V21a3.11,3.11,0,1,0-6.23,0v4.79A3.15,3.15,0,0,0,57.51,28.94Zm0,57.12a3.15,3.15,0,0,0-3.11,3.18V94a3.11,3.11,0,1,0,6.23,0V89.23A3.15,3.15,0,0,0,57.51,86.06ZM39.26,31.61a3.1,3.1,0,0,0,1.89,1.48,3,3,0,0,0,2.36-.32A3.15,3.15,0,0,0,45,30.84a3.21,3.21,0,0,0-.31-2.41l-2.35-4.15a3.1,3.1,0,0,0-1.89-1.48,3,3,0,0,0-2.36.32A3.15,3.15,0,0,0,36.61,25a3.21,3.21,0,0,0,.31,2.41ZM25,42L29,44.38a3,3,0,0,0,1.55.42,3.1,3.1,0,0,0,2.7-1.59,3.22,3.22,0,0,0-1.14-4.34l-4.06-2.39a3,3,0,0,0-2.36-.32,3.1,3.1,0,0,0-1.89,1.48A3.22,3.22,0,0,0,25,42ZM29.52,57.5a3.15,3.15,0,0,0-3.11-3.18H21.72a3.18,3.18,0,0,0,0,6.35h4.69A3.15,3.15,0,0,0,29.52,57.5ZM29,70.62L25,73a3.15,3.15,0,0,0-1.45,1.93,3.21,3.21,0,0,0,.31,2.41,3.1,3.1,0,0,0,2.7,1.59,3.05,3.05,0,0,0,1.55-.42l4.06-2.39a3.22,3.22,0,0,0,1.14-4.34A3.08,3.08,0,0,0,29,70.62Zm14.5,11.62a3,3,0,0,0-2.36-.32,3.1,3.1,0,0,0-1.89,1.48l-2.35,4.15A3.21,3.21,0,0,0,36.61,90a3.15,3.15,0,0,0,1.45,1.93,3,3,0,0,0,1.55.43,3.1,3.1,0,0,0,2.7-1.59l2.35-4.15A3.21,3.21,0,0,0,45,84.16,3.15,3.15,0,0,0,43.51,82.23ZM76.94,23.12a3,3,0,0,0-2.36-.32,3.1,3.1,0,0,0-1.89,1.48l-2.35,4.15A3.21,3.21,0,0,0,70,30.84a3.15,3.15,0,0,0,1.45,1.93,3,3,0,0,0,2.36.32,3.1,3.1,0,0,0,1.89-1.48l2.35-4.15A3.21,3.21,0,0,0,78.39,25,3.15,3.15,0,0,0,76.94,23.12Zm7.49,21.69A3,3,0,0,0,86,44.38L90,42a3.15,3.15,0,0,0,1.45-1.93,3.21,3.21,0,0,0-.31-2.41,3.08,3.08,0,0,0-4.25-1.16l-4.06,2.39a3.15,3.15,0,0,0-1.45,1.93,3.21,3.21,0,0,0,.31,2.41A3.1,3.1,0,0,0,84.43,44.81Zm8.85,9.51H88.59a3.18,3.18,0,0,0,0,6.35h4.69A3.18,3.18,0,0,0,93.28,54.32ZM90,73L86,70.62a3.08,3.08,0,0,0-4.25,1.16,3.22,3.22,0,0,0,1.14,4.34l4.06,2.39a3,3,0,0,0,1.55.42,3.11,3.11,0,0,0,2.7-1.59,3.21,3.21,0,0,0,.31-2.41A3.15,3.15,0,0,0,90,73ZM75.74,83.39a3.1,3.1,0,0,0-1.89-1.48,3,3,0,0,0-2.36.32,3.22,3.22,0,0,0-1.14,4.34l2.35,4.15a3.1,3.1,0,0,0,2.7,1.59,3,3,0,0,0,1.55-.42A3.15,3.15,0,0,0,78.39,90a3.21,3.21,0,0,0-.31-2.41Z" />
  </svg>
)

Sun.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#fff',
}

export default Sun
