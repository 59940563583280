import { UPDATE_SONOS } from '../actions/sonos'

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SONOS:
      const { room, data } = action.payload
      return { ...state, [room]: { ...state[room], ...data } }
    default:
      return state
  }
}
