import React from 'react'
import CrossFade from '../CrossFade'
import Widget from '../Widget'
import '../../styles/sonos.scss'
import _ from 'lodash'

import * as Icons from 'glob:../icons/*.js'

export default class Sonos extends Widget {
  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [
        ['Small', 'small'],
        ['Normal', 'normal'],
      ],
    },
    room: {
      name: 'Room',
      type: 'select',
      options: [
        ['Building 2', 'building_2'],
        ['Office: Aaron', 'office_aaron'],
        ['Office: Berg', 'office_berg'],
        ['Office: Daniel', 'office_daniel'],
        ['Office: Jeremy', 'office_jeremy'],
        ['Office: Jess', 'office_jess'],
        ['Office: Scott', 'office_scott'],
        ['Office: Spills', 'office_spills'],
        ['Orange Room', 'orange_room'],
        ['Team Room 1', 'team_room_1'],
        ['Team Room 2', 'team_room_2'],
        ['Team Room 3', 'team_room_3'],
        ['Team Room 4', 'team_room_4'],
        ['Team Room 5', 'team_room_5'],
        ['The Pit', 'the_pit'],
      ],
    },
  }

  static defaultSettings = {
    room: 'building_2',
    size: 'normal',
  }

  static getCardData(state, settings) {
    return state.data['sonos'][settings.room]
  }

  constructor(props) {
    super(props)
    this.state = props.data
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.title !== this.state.title || nextProps.room !== this.props.room
    )
  }

  componentWillReceiveProps(nextProps) {
    let image = new Image()
    image.onload = () => this.setState(nextProps.data)
    image.src = nextProps.data.cover || '/assets/pico-sonos.svg'
  }

  get roomName() {
    let room = this.constructor.settings.room.options.find(option => {
      return option[1] === this.props.room
    })

    return room[0]
  }

  renderPlaying() {
    const { size } = this.props
    const { album, artist, cover, title } = this.state

    return (
      <div className="sonos-track split-card">
        <div className="no-header-icon">
          <Icons.Song />
        </div>
        <div className="sonos-info split-info">
          <div className="sonos-info--content split-info--content">
            <h2>{_.truncate(title, { length: 60 })}</h2>
            <div className="card-details">
              <span className="song-artist">
                {_.truncate(artist, { length: 60 })}
              </span>
            </div>
          </div>
          {size === 'normal' && (
            <span className="room-name">{this.roomName}</span>
          )}
        </div>
        <div className="sonos-img-container split-img-container">
          <div className="album-cover">
            <img src={cover} />
          </div>
        </div>
      </div>
    )
  }

  renderNotPlaying() {
    const { size } = this.props

    return (
      <div className="sonos sonos-not-playing split-card">
        <div className="no-header-icon">
          <Icons.Song />
        </div>
        <div className="sonos-info split-info">
          <div className="sonos-info--content split-info--content">
            <h2>Nothin Playin</h2>
            <div className="card-details">
              <span className="song-title">So Pico is snoozin</span>
            </div>
          </div>
          {size === 'normal' && (
            <span className="room-name">{this.roomName}</span>
          )}
        </div>
        <div className="sonos-img-container split-img-container">
          <img src="/assets/dashboard-no-music.svg" />
        </div>
      </div>
    )
  }

  render() {
    return (
      <CrossFade>
        {this.state.title ? this.renderPlaying() : this.renderNotPlaying()}
      </CrossFade>
    )
  }
}
