import * as Icons from 'glob:../icons/*.js'
import React from 'react'
import { formatCount, formatPercent, formatSeconds } from '../../utils/format'
import { appColors } from '../../utils/mobile_app_colors'
import { appLinks } from '../../utils/mobile_app_links'
import { appDisplayNames } from '../../utils/mobile_app_names'
import AppIcon from '../AppIcon'
import { Tooltip } from '../Tooltip'
import Widget from '../Widget'

import '../../styles/mobile-overview.scss'

function MobileOverviewInfo({ platform, data, props, app }) {
  const logo = platform === 'ios' ? <Icons.Apple /> : <Icons.Android />
  const logoLink = platform === 'ios' ? appLinks[app]?.ios?.appleStore : appLinks[app]?.android.googlePlay

  const {
    appVersionVisible,
    appRatingVisible,
    appDownloadsVisible,
    appCrashVisible,
  } = props

  const children = []
  if (appVersionVisible) {
    children.push({
      tooltip: 'Version',
      field: 'version'
    })
  }
  if (appRatingVisible) {
    children.push({
      tooltip: 'Rating',
      field: 'rating',
      type: 'decimal',
      icon: <Icons.StarOutline />
    })
  }
  if (appDownloadsVisible) {
    children.push({
      tooltip: 'Downloads',
      field: 'platform_newUsers',
      type: 'count',
      icon: <Icons.Download />
    })
  }
  if (appCrashVisible) {
    children.push({
      tooltip: 'Crash Free %',
      field: 'crashFreeUsersRate',
      icon: <Icons.Check />,
      type: 'percent'
    })
  }

  function formatValue(value, type) {
    const float = parseFloat(Number(value), 10)
    if (!value) return 'N/A'

    switch (type) {
      case 'percent':
        return formatPercent(float)
      case 'time':
        return formatSeconds(float)
      case 'count':
        return formatCount(float, 1)
      case 'decimal':
        return float.toFixed(1)
      default:
        return value
    }
  }

  return (
    <div>
      <div className="mobile-overview--logo">
        <a href={logoLink} target="_blank">
          {logo}
        </a>
      </div>
      {children.slice(0, 3).map(({ tooltip, field, icon, type }) =>
        <Tooltip key={`info--${field}`} tooltip={tooltip} className="mobile-overview--info-item">
          {Boolean(icon) && icon}
          <p className={field}>{formatValue(data[platform][field], type)}</p>
        </Tooltip>
      )}
    </div>

  )
}

class MobileOverview extends Widget {
  static dataSource = 'mobileOverview'

  static settings = {
    appVersionVisible: {
      name: 'Version',
      type: 'checkbox'
    },
    appRatingVisible: {
      name: 'Rating',
      type: 'checkbox'
    },
    appDownloadsVisible: {
      name: 'Downloads',
      type: 'checkbox'
    },
    appCrashVisible: {
      name: 'Crash %',
      type: 'checkbox'
    },
  }

  static defaultSettings = {
    size: 'normal',
    appVersionVisible: true,
    appRatingVisible: true,
    appDownloadsVisible: true
  }

  render() {
    const appNames = Object.entries(appDisplayNames)

    const data = {
      android: {
        version: 'Not Set',
        rating: 0,
        reviewCount: 0,
        crashFreeUsersRate: 0,
        launch: 0,
        adoptionPercent: 0,
        platform_newUsers: 0,
      },
      ios: {
        version: 'Not Set',
        rating: 0,
        reviewCount: 0,
        crashFreeUsersRate: 0,
        launch: 0,
        adoptionPercent: 0,
        platform_newUsers: 0,
      }
    }

    const getAppData = (appName) => {
      return this.props.data[appName] || data
    }

    const getTotalUsers = () => {
      return appNames.reduce((sum, name) => {
        sum += Number(getAppData(name[0]).ios.platform_newUsers) || 0
        sum += Number(getAppData(name[0]).android.platform_newUsers) || 0

        return sum
      }, 0).toLocaleString('en-US')
    }

    return (
      <div className="mobile-overview">
        <div className="mobile-overview--apps">
          <div className="mobile-overview--app title">
            <div className="mobile-overview--icon">
              <Icons.Apps fill="currentColor" />
            </div>
            <h4>Mobile Apps</h4>
            <div className="mobile-overview--user-count">
              <Icons.Person />
              <h6>{getTotalUsers()}</h6>
            </div>
          </div>
          {appNames.map(([appKey, appName]) => (
            <div key={appKey} className="mobile-overview--app" style={{ backgroundColor: appColors[appKey].appColor }}>
              <div className="mobile-overview--icon">
                <AppIcon name={appKey} fill={'#fff'} />
              </div>
              <h4>{appName}</h4>
              <div className="mobile-overview--info">
                <MobileOverviewInfo platform="ios" data={getAppData(appKey)} props={this.props} app={appKey} />
                <MobileOverviewInfo platform="android" data={getAppData(appKey)} props={this.props} app={appKey} />
              </div>

            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default MobileOverview
