import _ from 'underscore'
import React from 'react'
import moment from 'moment'
import Widget from '../Widget'
import * as Icons from 'glob:../icons/*.js'

import '../../styles/calendar.scss'

export default class Calendar extends Widget {
  static settings = {
    size: {
      name: 'Size',
      type: 'select',
      options: [['Small', 'small'], ['Normal', 'normal'], ['Tall', 'tall']],
    },
    calendarId: {
      name: 'Google Calendar ID',
      type: 'input',
    },
    updateEvery: {
      name: 'Update Every (minutes)',
      type: 'number',
    },
    eventsFor: {
      name: 'Events For',
      type: 'select',
      options: [
        ['Today', 'today'],
        ['Today & Tomorrow', 'today_and_tomorrow'],
        ['This Week', 'this_week'],
      ],
    },
  }

  static defaultSettings = {
    calendarId: '',
    size: 'normal',
    updateEvery: '5',
    eventsFor: 'today',
  }

  static widgetProperties(newProps) {
    const { size } = newProps
    switch (size) {
      case 'small':
        return {
          height: 1,
          width: 3,
        }
        break
      case 'normal':
        return {
          height: 2,
          width: 3,
        }
      case 'tall':
        return {
          height: 4,
          width: 3,
        }
        break
    }
  }

  static getCardData(state, settings) {
    const {
      settings: { defaultTheme },
    } = state
    return { defaultTheme }
  }

  constructor(props) {
    super(props)
    this.state = { events: [], error: null }
  }

  componentWillMount() {
    this.fetchEvents()
    this.interval = setInterval(
      () => this.fetchEvents(),
      parseInt(this.props.updateEvery) * 60 * 1000 // Minutes to milliseconds
    )
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.calendarId !== prevProps.calendarId ||
      this.props.eventsFor !== prevProps.eventsFor
    ) {
      this.setState({ events: [] })
      this.fetchEvents()
    }

    if (this.props.updateEvery !== prevProps.updateEvery) {
      clearInterval(this.interval)
      this.interval = setInterval(
        () => this.fetchEvents(),
        parseInt(this.props.updateEvery) * 60 * 1000 // Minutes to milliseconds
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.data.defaultTheme !== this.props.data.defaultTheme ||
      nextProps.eventsFor !== this.props.eventsFor ||
      nextProps.calendarId !== this.props.calendarId ||
      nextProps.updateEvery !== this.props.updateEvery ||
      nextState.events !== this.state.events
    )
  }

  get currentEvent() {
    return this.state.events[0]
  }

  get eventsForDays() {
    switch (this.props.eventsFor) {
      case 'today':
        return 0
        break
      case 'today_and_tomorrow':
        return 1
        break
      case 'this_week':
        return 6 - moment().day()
        break
    }
  }

  get eventsForString() {
    switch (this.props.eventsFor) {
      case 'today':
        return 'today'
        break
      case 'today_and_tomorrow':
        return 'today or tomorrow'
        break
      case 'this_week':
        return 'this week'
        break
    }
  }

  fetchEvents() {
    const { calendarId } = this.props

    if (calendarId.length > 0) {
      let timeMin = new Date()
      let timeMax = new Date(timeMin)
      timeMax.setDate(timeMax.getDate() + this.eventsForDays)
      timeMax.setHours(23, 59, 59, 999)

      const calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`

      fetch(
        `${calendarUrl}?key=AIzaSyBp3G-TG4cZYOq-DlkGciD8aczOmcKX7gI&timeMin=${timeMin.toISOString()}&timeMax=${timeMax.toISOString()}&singleEvents=true&orderBy=startTime`
      ).then(response => {
        if (response.ok) {
          response.json().then(calendar => {
            this.setState(
              { events: calendar.items, error: null },
              this.loadNextEvent
            )
          })
        } else {
          this.setState({ error: true, events: [] })
        }
      })
    }
  }

  renderCurrentEvent() {
    const { description, start, end, summary, location } = this.currentEvent
    const eventStartDate = moment(start.dateTime || start.date)
    const eventEndDate = moment(end.dateTime)

    return (
      <div className="calendar-event--small event-row">
        <div className="calendar-content calendar-content--small">
          <div className="event-row--top">
            <div>
              <strong>
                {start.date
                  ? 'All Day'
                  : `${eventStartDate.format('h:mm')}-${eventEndDate.format(
                      'LT'
                    )}`}
              </strong>{' '}
              <em>{eventStartDate.fromNow()}</em>
            </div>
            <div className="event-name">{summary}</div>
          </div>
          {location && (
            <div className="event-location">
              <Icons.Marker />
              <em>{location}</em>
            </div>
          )}
        </div>
      </div>
    )
  }

  renderAllEvents() {
    const groupedEvents = _.groupBy(this.state.events, event => {
      return moment(event.start.dateTime || event.start.date).format(
        'YYYY-MM-DD'
      )
    })

    return (
      <ul
        className="calendar-list-items"
        style={{ listStyle: 'none', padding: '0' }}
      >
        {_.map(groupedEvents, (events, date) => {
          return (
            <li key={date}>
              <h4 className="calendar-list--date-header">
                <span>{moment(date).format('ddd')}</span>
                <span className="calendar-date">
                  {moment(date).format('D')}
                </span>
              </h4>
              <ul
                className="calendar-list-items--content"
                style={{ listStyle: 'none' }}
              >
                {events.map((event, index) => {
                  const { start, end, summary, location } = event
                  const eventStartDate = moment(start.dateTime)
                  const eventEndDate = moment(end.dateTime)
                  return (
                    <li
                      className="event-row"
                      key={index}
                      style={{
                        padding: 16,
                        margin: 8,
                      }}
                    >
                      <div className="event-row--top">
                        <strong>
                          {start.date
                            ? 'All Day'
                            : `${eventStartDate.format(
                                'h:mm'
                              )}-${eventEndDate.format('LT')}`}
                        </strong>
                        <div className="event-name">{summary}</div>
                      </div>
                      {location && (
                        <div className="event-location">
                          <Icons.Marker />
                          <em>{location}</em>
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    )
  }

  renderNoEvents() {
    return (
      <div className="calendar-event">
        <div className="calendar-content calender-content--blank-state">
          <img
            className="blank-state-image"
            src="/assets/dashboard-calendar.svg"
          />
          <p>No events {this.eventsForString}!</p>
        </div>
      </div>
    )
  }

  renderError() {
    return (
      <div className="calendar-event">
        <div className="calendar-content calender-content--blank-state">
          <img
            className="blank-state-image"
            src="/assets/dashboard-error.svg"
          />
          <p>
            There was a problem finding your calendar.
            <br />
            Did you forget to make it public?
          </p>
        </div>
      </div>
    )
  }

  renderWelcome() {
    return (
      <div className="calendar-event">
        <div className="calendar-content calender-content--welcome calender-content--blank-state">
          <img
            className="blank-state-image"
            src="/assets/dashboard-google-id.svg"
          />
          <p>Please enter a Google Calendar ID.</p>
        </div>
      </div>
    )
  }

  render() {
    const { size, calendarId } = this.props

    return (
      <div className="calendar">
        <div className={`calendar-${size}-widget`}>
          <div className={size === 'small' ? 'no-header-icon' : 'card-header'}>
            <Icons.Calendar />
            {size !== 'small' && (
              <span>{moment().format('dddd, MMMM Do, YYYY')}</span>
            )}
          </div>
          <div className="card-body">
            {!calendarId.length
              ? this.renderWelcome()
              : this.state.error
              ? this.renderError()
              : this.currentEvent
              ? size === 'small'
                ? this.renderCurrentEvent()
                : this.renderAllEvents()
              : this.renderNoEvents()}
          </div>
        </div>
      </div>
    )
  }
}
