import classNames from 'classnames'
import React from 'react'
import capitalize from '../../utils/capitalize'
import AppIcon from '../AppIcon'
import Widget from '../Widget'
import Graph from './Stats/Graph'
import Meter from './Stats/Meter'
import Server from './Stats/Server'

import '../../styles/stats.scss'

class Stats extends Widget {
  static settings = {
    app: {
      name: 'App',
      type: 'select',
      options: [
        ['Accounts', 'accounts'],
        ['Api', 'api'],
        ['Check Ins', 'check_ins'],
        ['Giving', 'giving'],
        ['Groups', 'groups'],
        ['People', 'people'],
        ['Registrations', 'registrations'],
        ['Calendar', 'calendar'],
        ['Services', 'services'],
      ],
    },
    bugsnagVisible: {
      name: 'Bugsnag',
      type: 'checkbox',
    },
    errorRateVisible: {
      name: 'Error Rate',
      type: 'checkbox',
    },
    graphVisible: {
      name: 'Graph',
      type: 'checkbox',
    },
    jobsVisible: {
      name: 'Jobs',
      type: 'checkbox',
    },
    onCallVisible: {
      name: 'On Call Person',
      type: 'checkbox',
    },
    meterVisible: {
      name: 'Response Time',
      type: 'checkbox',
    },
    rpmVisible: {
      name: 'RPM',
      type: 'checkbox',
    },
    workersVisible: {
      name: 'Workers',
      type: 'checkbox',
    },
  }

  static config = {
    accounts: {
      appColor: '#3980aa',
      fillColor: '#48a6dd',
    },
    api: {
      appColor: '#6592aa',
      fillColor: '#3b9ccf',
    },
    check_ins: {
      appColor: '#7057a6',
      fillColor: '#8a6bcb',
    },
    giving: {
      appColor: '#ffb129',
      fillColor: '#ffdf91',
    },
    groups: {
      appColor: '#f58a4f',
      fillColor: '#ffb48b',
    },
    people: {
      appColor: '#526ba3',
      fillColor: '#81a3f0',
    },
    registrations: {
      appColor: '#4ca69e',
      fillColor: '#74ddd4',
    },
    calendar: {
      appColor: '#dd5d4f',
      fillColor: '#ffaca3',
    },
    services: {
      appColor: '#448b27',
      fillColor: '#82c069',
    },
  }

  static defaultSettings = {
    app: 'accounts',
    bugsnagVisible: false,
    deploysVisible: false,
    errorRateVisible: false,
    graphVisible: false,
    jobsVisible: false,
    meterVisible: true,
    onCallVisible: false,
    rpmVisible: true,
    tasksVisible: false,
    workersVisible: false,
  }

  static defaultProps = {
    data: {
      bugsnag: '',
      deploys: {},
      graph: { points: [] },
      metrics: {},
      onCall: '',
    },
  }

  static widgetProperties(newProps) {
    const { graphVisible, meterVisible } = newProps

    const activeProps = Object.keys(newProps).filter(
      prop => newProps[prop] === true
    )
    const activeNotGraphOrChart = activeProps.filter(
      prop => prop !== 'graphVisible' && prop !== 'meterVisible'
    )

    if ((graphVisible && meterVisible) || activeNotGraphOrChart.length > 4) {
      return {
        height: 4,
        width: 3,
      }
    } else {
      return {
        height: 2,
        width: 3,
      }
    }
  }

  static getCardData(state, settings) {
    return state.data['stats'][settings.app]
  }

  get panelClasses() {
    const currentFailedOver = this.props.current_failed > 10
    const errorRateOver = parseFloat(this.props.error_rate) >= 0.25
    const responseTimeOver = this.props.response_time >= 250
    const warning = currentFailedOver || errorRateOver || responseTimeOver
    return classNames('column', { row_warning: warning })
  }

  get data() {
    return { ...this.constructor.defaultProps.data, ...this.props.data }
  }

  render() {
    const {
      app,
      bugsnagVisible,
      deploysVisible,
      errorRateVisible,
      graphVisible,
      jobsVisible,
      meterVisible,
      onCallVisible,
      rpmVisible,
      tasksVisible,
      workersVisible,
    } = this.props
    const { bugsnag, deploys, graph, jobs, metrics, onCall } = this.data
    const { fillColor, appColor } = Stats.config[app]
    const serverVisible =
      rpmVisible ||
      errorRateVisible ||
      jobsVisible ||
      workersVisible ||
      onCallVisible ||
      bugsnagVisible
    return (
      <div className="card-front stats">
        <div className="card-left">
          <div className="stats-app">
            <AppIcon name={app} className="stats-app__icon" />
            <h1 className="stats-app__name">
              {capitalize(app).replace('_', '-')}
            </h1>
          </div>
          {serverVisible && (
            <div className="stats-meta">
              <Server
                {...metrics}
                {...jobs}
                bugsnag={bugsnag}
                onCall={onCall}
                bugsnagVisible={bugsnagVisible}
                errorRateVisible={errorRateVisible}
                jobsVisible={jobsVisible}
                onCallVisible={onCallVisible}
                rpmVisible={rpmVisible}
                workersVisible={workersVisible}
              />
            </div>
          )}
        </div>
        <div className="card-right">
          <div id={app} className={this.panelClasses}>
            {meterVisible && (
              <Meter
                value={metrics.response_time}
                fillColor={fillColor}
                appColor={appColor}
              />
            )}
            {graphVisible && (
              <Graph points={graph.points} title={app} fillColor={fillColor} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Stats
