import React from 'react'

const Bell = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M99.303,69.432l-6.43-16.167c-0.392-0.986-1.159-1.775-2.134-2.195c-0.975-0.42-2.075-0.436-3.062-0.043
			c-1.959,0.78-4.107,0.749-6.045-0.086c-1.938-0.835-3.435-2.375-4.215-4.336l-3.566-8.965C69.471,26.62,58.7,19.392,47.016,19.302
			c-1.179-2.067-2.986-3.688-5.209-4.647c-2.709-1.167-5.709-1.209-8.446-0.12c-2.74,1.089-4.892,3.18-6.058,5.888
			c-0.959,2.223-1.159,4.644-0.596,6.955c-8.432,8.09-11.297,20.741-6.915,31.76l3.566,8.966c0.779,1.961,0.749,4.108-0.086,6.046
			s-2.375,3.435-4.337,4.215c-0.985,0.392-1.775,1.16-2.195,2.134s-0.436,2.075-0.043,3.061l6.43,16.167
			c0.623,1.568,2.128,2.523,3.718,2.523c0.492,0,0.992-0.091,1.478-0.284l19.654-7.816c3.116,4.19,8.039,6.61,13.165,6.61
			c2.018,0,4.066-0.375,6.046-1.162c7.009-2.788,11.077-9.928,10.223-17.154l19.654-7.816c0.985-0.392,1.775-1.16,2.195-2.134
			S99.695,70.418,99.303,69.432z M64.23,92.163c-2.662,1.059-5.567,0.659-7.797-0.839l12.889-5.125
			C68.73,88.819,66.894,91.104,64.23,92.163z M29.082,93.053l-3.659-9.202c2.266-1.655,4.057-3.891,5.196-6.535
			c1.681-3.9,1.742-8.222,0.173-12.169l-3.566-8.966c-3.44-8.652-0.719-18.692,6.619-24.415c1.401-1.093,1.914-2.98,1.257-4.632
			l-0.485-1.221c-0.3-0.753-0.288-1.579,0.033-2.324c0.32-0.745,0.913-1.32,1.666-1.62c0.757-0.3,1.581-0.288,2.324,0.033
			c0.745,0.321,1.32,0.913,1.621,1.669l0.486,1.221c0.657,1.651,2.33,2.672,4.094,2.501c9.261-0.875,18.137,4.55,21.577,13.203
			l3.566,8.965c1.569,3.947,4.582,7.046,8.482,8.727c2.645,1.14,5.479,1.537,8.265,1.182l3.659,9.202L29.082,93.053z"
    />
  </svg>
)

Bell.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Bell
