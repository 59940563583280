import { TOGGLE_SIDEBAR, TOGGLE_SETTINGS_ACTIVE } from '../actions/status'
import { SAVE_DASHBOARD } from '../actions/dashboard'

const initialState = {
  sidebarVisible: false,
  settingsActive: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DASHBOARD:
      return { ...state, sidebarVisible: false }
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarVisible: !state.sidebarVisible }
    case TOGGLE_SETTINGS_ACTIVE:
      const { id } = action.payload

      if (state.settingsActive.indexOf(id) === -1) {
        return { ...state, settingsActive: state.settingsActive.concat(id) }
      } else {
        return {
          ...state,
          settingsActive: state.settingsActive.filter(
            widgetId => widgetId !== id
          ),
        }
      }
    default:
      return state
  }
}
