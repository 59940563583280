import React from 'react'

const Twitter = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M70.583,30.265c3.996,0,7.608,1.7,10.142,4.422c2.352-0.467,4.598-1.23,6.698-2.25c0.128-0.062,0.257-0.09,0.381-0.09
			c0.604,0,1.087,0.667,0.76,1.28c-1.02,1.912-2.468,3.558-4.213,4.809c-0.2,0.143-0.094,0.452,0.135,0.452
			c0.014,0,0.028-0.001,0.043-0.003c1.419-0.228,2.801-0.562,4.14-0.996c0.096-0.031,0.19-0.045,0.28-0.045
			c0.685,0,1.154,0.83,0.664,1.42c-1.502,1.813-3.227,3.436-5.127,4.818c0.027,0.601,0.041,1.205,0.041,1.812
			c0,18.505-13.976,39.843-39.531,39.843c-6.642,0-12.895-1.662-18.388-4.582c-0.74-0.393-0.466-1.514,0.371-1.514h0.001l0.034,0
			c5.875,0,11.329-1.824,15.832-4.942c0.487-0.337,0.308-1.106-0.279-1.189c-4.603-0.656-8.484-3.584-10.483-7.623
			c-0.392-0.791,0.205-1.733,1.074-1.733c0.021,0,0.041,0.001,0.062,0.002c0.226,0.012,0.455,0.017,0.685,0.017
			c0.618,0,1.228-0.041,1.825-0.12c0.457-0.06,0.525-0.701,0.09-0.854c-5.389-1.908-8.713-7.082-9.122-10.54
			c0,0-0.031-0.33-0.073-0.766c-0.073-0.755,0.523-1.331,1.214-1.331c0.148,0,0.301,0.027,0.453,0.084
			c0.826,0.312,1.689,0.547,2.581,0.697c0.032,0.005,0.063,0.008,0.094,0.008c0.459,0,0.709-0.587,0.339-0.911
			c-2.925-2.568-4.772-6.354-4.772-10.57c0-1.88,0.369-3.675,1.035-5.313c0.196-0.481,0.657-0.743,1.122-0.743
			c0.327,0,0.656,0.129,0.897,0.401c6.723,7.581,16.279,12.551,26.995,13.22c0.008,0,0.015,0.001,0.023,0.001
			c0.224,0,0.392-0.208,0.349-0.432c-0.236-1.207-0.316-2.47-0.226-3.766c0.438-6.318,5.22-11.695,11.414-12.76
			C68.966,30.333,69.782,30.265,70.583,30.265 M70.583,23.194L70.583,23.194c-1.21,0-2.435,0.105-3.642,0.313
			c-8.167,1.405-14.744,7.762-16.714,15.711c-5.874-1.666-11.177-4.989-15.351-9.696c-1.57-1.768-3.824-2.781-6.188-2.781
			c-3.386,0-6.397,2.019-7.671,5.145c-1.035,2.542-1.559,5.227-1.559,7.982c0,2.586,0.472,5.117,1.364,7.481
			c-0.972,1.542-1.417,3.382-1.239,5.225l0.071,0.747l0.008,0.084l0.01,0.083c0.408,3.45,2.242,7.429,5.289,10.726
			c-0.229,1.617,0.029,3.286,0.782,4.808c0.623,1.258,1.361,2.436,2.2,3.525c-0.31,0.014-0.62,0.021-0.932,0.021
			c-3.654,0-6.795,2.453-7.672,5.966c-0.877,3.517,0.746,7.161,3.947,8.863c6.656,3.539,14.163,5.41,21.708,5.41
			c13.674,0,25.988-5.469,34.672-15.398c7.242-8.281,11.535-19.133,11.905-29.989c1.244-1.128,2.41-2.346,3.486-3.645
			c1.982-2.392,2.394-5.622,1.074-8.43c-0.144-0.306-0.306-0.6-0.485-0.881c0.293-1.812-0.055-3.687-1.038-5.325
			c-1.43-2.383-4.038-3.864-6.805-3.864c0,0-0.001,0-0.002,0c-1.191,0-2.391,0.277-3.47,0.802c-0.619,0.301-1.252,0.57-1.897,0.807
			C78.971,24.506,74.832,23.194,70.583,23.194L70.583,23.194L70.583,23.194z"
    />
  </svg>
)

Twitter.defaultProps = {
  width: '40px',
  height: '40px',
  fill: '#c3c3c3',
}

export default Twitter
