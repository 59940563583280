import React from 'react'

const Cog = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 116 116" fill={fill}>
    <path
      d="M92.614,54.527c-0.002-0.017-0.003-0.034-0.005-0.051c-0.061-0.586-0.465-1.064-1.016-1.235
			c-0.075-0.023-0.151-0.04-0.231-0.051c-0.018-0.003-0.035-0.01-0.054-0.012c-1.574-0.174-3.148-0.348-4.722-0.521
			c-4.935-0.788-7.521-7.062-4.597-11.096c0.99-1.236,1.98-2.472,2.97-3.707c0.028-0.035,0.046-0.073,0.07-0.11
			c0.012-0.017,0.023-0.035,0.034-0.053c0.031-0.051,0.059-0.103,0.082-0.157c0.025-0.053,0.045-0.108,0.063-0.163
			c0.006-0.02,0.012-0.039,0.018-0.06c0.122-0.438,0.035-0.917-0.261-1.29c-0.014-0.018-0.029-0.036-0.044-0.054
			c-0.022-0.025-0.038-0.052-0.062-0.076l-4.761-4.761c-0.024-0.024-0.052-0.041-0.078-0.063c-0.013-0.011-0.026-0.022-0.039-0.032
			c-0.457-0.371-1.081-0.424-1.592-0.155c-0.069,0.037-0.135,0.079-0.199,0.127c-0.015,0.011-0.032,0.018-0.046,0.03
			c-1.236,0.99-2.472,1.98-3.708,2.971c-4.056,2.94-10.313,0.32-11.097-4.595c-0.174-1.574-0.348-3.148-0.521-4.722
			c-0.005-0.044-0.019-0.085-0.028-0.127c-0.004-0.021-0.009-0.041-0.013-0.061c-0.015-0.058-0.031-0.114-0.052-0.169
			c-0.02-0.055-0.044-0.108-0.071-0.16c-0.01-0.018-0.019-0.037-0.03-0.055c-0.224-0.396-0.624-0.673-1.096-0.727
			c-0.023-0.003-0.046-0.005-0.069-0.007c-0.033-0.002-0.064-0.01-0.098-0.01h-6.733c-0.034,0-0.066,0.008-0.1,0.01
			c-0.017,0.002-0.034,0.003-0.05,0.005c-0.586,0.061-1.064,0.465-1.236,1.016c-0.023,0.075-0.04,0.152-0.051,0.231
			c-0.003,0.018-0.01,0.035-0.012,0.054c-0.174,1.574-0.348,3.148-0.521,4.722c-0.789,4.947-7.057,7.525-11.096,4.597
			c-1.236-0.99-2.472-1.98-3.707-2.97c-0.035-0.028-0.073-0.046-0.11-0.07c-0.017-0.012-0.035-0.023-0.053-0.034
			c-0.051-0.031-0.103-0.059-0.157-0.082c-0.053-0.025-0.107-0.045-0.163-0.063c-0.02-0.006-0.04-0.013-0.06-0.018
			c-0.438-0.122-0.917-0.035-1.289,0.261c-0.019,0.014-0.036,0.029-0.054,0.044c-0.025,0.022-0.052,0.038-0.076,0.062l-4.761,4.761
			c-0.024,0.024-0.041,0.052-0.063,0.078c-0.011,0.013-0.022,0.026-0.032,0.04c-0.371,0.457-0.423,1.081-0.155,1.592
			c0.037,0.069,0.079,0.135,0.127,0.199c0.011,0.015,0.018,0.032,0.03,0.046c0.99,1.236,1.98,2.472,2.971,3.708
			c2.949,4.069,0.341,10.309-4.595,11.097c-1.574,0.174-3.148,0.348-4.722,0.521c-0.044,0.005-0.085,0.019-0.127,0.028
			c-0.021,0.004-0.041,0.009-0.061,0.013c-0.058,0.015-0.114,0.031-0.169,0.052c-0.055,0.02-0.108,0.044-0.16,0.071
			c-0.018,0.01-0.037,0.019-0.055,0.03c-0.396,0.224-0.673,0.624-0.727,1.096c-0.003,0.023-0.005,0.046-0.007,0.07
			c-0.002,0.033-0.01,0.064-0.01,0.097v6.733c0,0.034,0.008,0.066,0.01,0.1c0.002,0.017,0.003,0.034,0.005,0.051
			c0.061,0.586,0.465,1.064,1.016,1.235c0.075,0.023,0.152,0.04,0.231,0.051c0.018,0.003,0.035,0.01,0.054,0.012
			c1.574,0.174,3.148,0.348,4.722,0.521c4.958,0.791,7.533,7.046,4.597,11.096c-0.99,1.236-1.98,2.472-2.97,3.708
			c-0.028,0.035-0.046,0.073-0.07,0.11c-0.012,0.017-0.023,0.035-0.034,0.053c-0.031,0.051-0.059,0.103-0.082,0.157
			c-0.025,0.053-0.045,0.108-0.063,0.163c-0.006,0.02-0.013,0.04-0.018,0.06c-0.122,0.438-0.035,0.917,0.261,1.289
			c0.014,0.019,0.029,0.036,0.044,0.054c0.022,0.025,0.038,0.052,0.062,0.076l4.761,4.761c0.024,0.024,0.052,0.041,0.077,0.063
			c0.013,0.011,0.026,0.022,0.04,0.033c0.457,0.371,1.08,0.423,1.592,0.155c0.069-0.037,0.135-0.079,0.199-0.127
			c0.015-0.011,0.032-0.018,0.046-0.03c1.236-0.99,2.472-1.98,3.708-2.971c4.046-2.932,10.317-0.294,11.096,4.595
			c0.174,1.574,0.348,3.148,0.521,4.722c0.005,0.044,0.019,0.085,0.028,0.127c0.004,0.021,0.009,0.041,0.013,0.061
			c0.015,0.058,0.031,0.114,0.052,0.169c0.02,0.055,0.044,0.108,0.071,0.16c0.01,0.018,0.019,0.037,0.03,0.055
			c0.224,0.396,0.624,0.673,1.096,0.727c0.023,0.003,0.046,0.005,0.07,0.007c0.033,0.002,0.064,0.01,0.097,0.01h6.733
			c0.034,0,0.066-0.008,0.1-0.01c0.017-0.002,0.034-0.003,0.051-0.005c0.586-0.061,1.064-0.465,1.235-1.016
			c0.023-0.075,0.04-0.151,0.051-0.231c0.003-0.018,0.01-0.035,0.012-0.054c0.174-1.574,0.348-3.148,0.521-4.722
			c0.557-5.045,7.203-7.42,11.096-4.598c1.236,0.99,2.472,1.98,3.707,2.97c0.035,0.028,0.073,0.046,0.11,0.07
			c0.017,0.012,0.035,0.023,0.053,0.034c0.051,0.031,0.103,0.059,0.157,0.082c0.053,0.025,0.108,0.045,0.163,0.063
			c0.02,0.006,0.04,0.013,0.06,0.018c0.438,0.122,0.917,0.035,1.289-0.261c0.018-0.014,0.036-0.029,0.054-0.044
			c0.025-0.022,0.052-0.038,0.076-0.062l4.761-4.761c0.024-0.024,0.041-0.052,0.063-0.077c0.011-0.013,0.022-0.026,0.032-0.04
			c0.371-0.457,0.423-1.081,0.155-1.592c-0.037-0.069-0.079-0.135-0.127-0.199c-0.011-0.015-0.018-0.032-0.03-0.046
			c-0.99-1.236-1.98-2.472-2.971-3.708c-2.949-4.069-0.334-10.31,4.595-11.097c1.574-0.174,3.148-0.348,4.722-0.521
			c0.044-0.005,0.085-0.019,0.127-0.028c0.021-0.004,0.041-0.009,0.061-0.013c0.058-0.015,0.114-0.031,0.169-0.052
			c0.055-0.02,0.108-0.044,0.16-0.071c0.018-0.01,0.037-0.019,0.055-0.03c0.396-0.224,0.673-0.624,0.727-1.096
			c0.003-0.023,0.005-0.046,0.007-0.07c0.002-0.033,0.01-0.064,0.01-0.097v-6.733C92.624,54.593,92.616,54.561,92.614,54.527z
			 M58,69.809c-6.522,0-11.809-5.287-11.809-11.809c0-6.522,5.287-11.809,11.809-11.809S69.809,51.478,69.809,58
			C69.809,64.522,64.522,69.809,58,69.809z"
    />
  </svg>
)

Cog.defaultProps = {
  width: '30px',
  height: '30px',
  fill: '#c3c3c3',
}

export default Cog
