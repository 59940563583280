import { combineReducers } from 'redux'
import active_users from './active_users'
import mobileOverview from './mobileoverview'
import personapps from './personapps'
import sonos from './sonos'
import stats from './stats'
import support from './support'
import tasks from './tasks'

export default combineReducers({
  active_users,
  personapps,
  mobileOverview,
  sonos,
  stats,
  support,
  tasks,
})
