import React from 'react'

const Firebase = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
    <path d="M8.56 4.94L2.54 15.34 5.83-.2zM9.67 7.02L10.87 9.27 5.34 14.5zM14.16 6.15L12.37 7.85 10.85 4.99 12.28 2.53zM11.83 24c-1.83-1-8.55-4.78-8.55-4.78L19.33 4.01 22 18C17 21 17 21 11.83 24z" />
  </svg>
)

Firebase.defaultProps = {
  width: '20px',
  height: '20px',
  fill: '#fff',
}

export default Firebase
